/*** Adobe Analytics AppMeasurement for JavaScript version: 2.9 ***/

var s_account = "schwansdev";
var site = document.location.hostname;
if (site === "www.yelloh.com") {
    s_account = "schwansprod";
}

var s = s_gi(s_account);

/************************** CONFIG SECTION **************************/
s.visitor = Visitor.getInstance("5C2A123F5245AF1F0A490D45@AdobeOrg");
s.charSet = "ISO-8859-1";
s.currencyCode = "USD";
s.trackDownloadLinks = true;
s.trackExternalLinks = true;
s.linkDownloadFileTypes = "exe,zip,wav,mp3,mov,mpg,avi,wmv,doc,pdf,xls";
s.linkInternalFilters = "javascript:,yelloh.com";
s.linkLeaveQueryString = false;
s.linkTrackVars = "None";
s.linkTrackEvents = "None";
var sentAjaxImage = "false";

/* Channel Manager Plugin Settings */
s._channelDomain='Social Networks Organic|facebook.com,linkedin.com,//t.co,twitter.com,pinterest.com,orkut.com,friendster.com,livejournal.com,friendfeed.com,myspace.com,twine.com,yelp.com,mixx.com,delicious.com,tumblr.com,disqus.com,intensedebate.com,plurk.com,slideshare.net,backtype.com,netvibes.com,mister-wong.com,diigo.com,flixster.com,youtube.com,vimeo.com,12seconds.tv,zooomr.com,identi.ca,jaiku.com,flickr.com,imeem.com,dailymotion.com,photobucket.com,fotolog.com,smugmug.com,classmates.com,myyearbook.com,mylife.com,tagged.com,brightkite.com,ning.com,bebo.com,hi5.com,yuku.com,cafemom.com,xanga.com';
s._channelPattern='Paid Search|GC,GK,YK,MSN>Social Networks Campaigns|fb_,tw_,pin_>Mbooth|mb_>Display|display';
s._channelParameter='Paid Search|SEARCH,CONTENT>Email|RRID';

/* Retail Search Plugin Config */
s.successfulSearchEvent = 'event1';
s.nullSearchEvent = 'event5';
s.searchTermVariable = 'eVar1';

s.usePlugins = true;

function s_doPlugins(s) {
    /* Add calls to plugins here */
    
    /* visitor ID service validation */
    s.prop50 = (typeof(Visitor) !== "undefined" ? "VisitorAPI Present" : "VisitorAPI Missing");
    
    //set currency event in s.events
    if(s.products){
        var prdstr=s.products;
        if(s.events){
            var evtstr=s.events;
            if(evtstr.indexOf('event84') !== -1){ //set modified order events from s.products in s.events
                evtstr="";
                if(prdstr.indexOf('event85') !== -1){evtstr='event85';}
                if(prdstr.indexOf('event90') !== -1){evtstr=evtstr +',event90';}
                if(prdstr.indexOf('event92') !== -1){evtstr=evtstr +',event92';}
                s.events=s.apl(s.events,evtstr);
            }
            else if(evtstr.indexOf('event81') !== -1){ //set cancelled order events from s.products in s.events
                evtstr="";
                if(prdstr.indexOf('event82') !== -1){evtstr='event82';}
                if(prdstr.indexOf('event83') !== -1){evtstr=evtstr +',event83';}
                if(prdstr.indexOf('event91') !== -1){evtstr=evtstr +',event91';}
                s.events=s.apl(s.events,evtstr);
            }
        }
    }
    
    //getTimeParting
	s.tpg = getTimeParting('-6').split('|');
	s.prop31 = [ s.tpg[4].split('=')[1]||'n/a', s.tpg[3].split('=')[1]||'n/a' ].join('|');
    
    s.events = s.events || '';
	
    //channelManager
	s.channelManager('cid,rmid,kwid,fb_ref',':','s_cm','','s_dl','14','1');	
	s.eVar31=s._channel;
	s.eVar32=s._keywords;
    
    //crossVisitParticipation 
	s.eVar33=s.crossVisitParticipation(s.eVar31,'s_atx','30','10',' > ','');

    /* External Campaigns */
    if (!s.campaign) 
        s.campaign = s.Util.getQueryParam('CID');
    if (!s.campaign)
        s.campaign = s.Util.getQueryParam('RMID');
    if (!s.campaign)
        s.campaign = s.Util.getQueryParam('kwid');
    if (!s.campaign)
        s.campaign = s.Util.getQueryParam('fb_ref');
    if (s.campaign)
        s.eVar3 = "External Campaigns";
    s.campaign = s.getValOnce(s.campaign, 's_campaign', 0);

    /* Internal Campaigns */
    s.eVar2 = !s.eVar2 && s.Util.getQueryParam('incmpid') ? s.Util.getQueryParam('incmpid') : "";
    s.eVar2 = s.eVar2 ? s.getValOnce(s.eVar2, 's_evar2', 0) : "";
    if (s.eVar2 && s.eVar2 !== ""){
        s.eVar3 = "Internal Campaigns"; //set product finding method
        s.eVar50 = s.crossVisitParticipation(s.eVar2,'s_intcmp','365','10',' > ','purchase','0'); //internal campaign stacking
        s.eVar51 = s.eVar2;
    }

    //determines if the URL is external and stores product finding method (eVar3)
    //if referrer is empty and eVar3 is not set, then eVar3='typed/bookmarked'
    if (document.referrer === '' && !s.eVar3)
        s.eVar3 = 'typed/bookmarked';
    var referrer = document.referrer.split('/');
    var rDomain = referrer[2] || '';
    var int_domains = s.linkInternalFilters.split(',');
    var isExternalURL = true;
    for (var i = 0; i < int_domains.length; i++) {
        if (rDomain.indexOf(int_domains[i]) !== -1)
            isExternalURL = false;
    };
    if (isExternalURL && !s.eVar3)
        s.eVar3 = 'external referrer';

    /* Responsys Recipient ID */
    if (!s.eVar6)
        s.eVar6 = s.Util.getQueryParam('RRID');

    /* Force search term to lower case */
    if (s.prop1)
        s.prop1 = s.prop1.toLowerCase();
    if (s.eVar3)
        s.eVar3 = s.eVar3.toLowerCase();

    //Do not refire search event if the same search term passed in twice
    var t_search = s.getValOnce(s[s.searchTermVariable], 'ev1', 0);
    if (t_search === '') {
        var a = s.events.split(',');
        var e = '';
        for (i = 0; i < a.length; i++) {
            if (a[i] === s.successfulSearchEvent)
                continue;
            else if (a[i] === s.nullSearchEvent)
                continue;
            else
                e += a[i] ? a[i] + ',' : a[i];
        }
        s.events = e.substring(0, e.length - 1);
    }
    if (t_search && !s.products)
        s.products = ";"

    /* de-duplicate state, zip and customer ID */
    s.eVar7 = s.getValOnce(s.prop4, 's_evar7', 0);
    s.eVar8 = s.getValOnce(s.prop5, 's_evar8', 0);
    s.eVar10 = s.getValOnce(s.prop6, 's_evar10', 0);

    /* get time from cart open to purchase */
    if (s.events && s.events.indexOf('scOpen') > -1)
        s.prop16 = 'start';

    if (s.events && s.events.indexOf('purchase') > -1)
        s.prop16 = 'stop';

    s.prop16 = s.getTimeToComplete(s.prop16, 'ttc', 90);

    var gpv_pn = s.getPreviousValue(s.pageName, 'gpv_pn', '');
	if(gpv_pn) {s.prop32=gpv_pn;}
	s.getPercentPageViewed();
	s.prop30 = s._ppvInitialPercentViewed;
	if(!s.prop32||s.prop32==='no value')s.prop30='';
	if(s.events&&s.events.indexOf('scAdd')>-1) {
		s.linkTrackVars=s.apl(s.linkTrackVars,'eVar21');
		if(gpv_pn) {
			s.eVar21=gpv_pn;
		}
	}

    //added 8/3/10 - automatically set event32 when event26 (promo opt out) and event27 (service opt out) are set at the same time
    if (s.events && s.events.indexOf('event26') !== -1 && s.events.indexOf('event27') !== -1)
        s.events = s.apl( s.events, 'event32' );

    //set the platform (if not already set)
    s.eVar30 = s.eVar30 ? s.eVar30: 'desktop';
    	
	//new/repeat visitor
	s.eVar36 = s.getNewRepeat();
	
	//TnT/SiteCatalyst integration
	//s.tnt=s.trackTNT();
    
    //Added 12/12/18 - Customer Identification
    function getCookieValue(a) {
        var b = document.cookie.match('(^|;)\\s*' + a + '\\s*=\\s*([^;]+)');
        return b ? b.pop() : '';
    }
    var cValue = getCookieValue('SSE_TT1');
    if(cValue!= 'undefined' && cValue == 1){
        //the visitor is a customer, find out if they are logged in or not
        if(s.prop6){ //prop6 is the customer ID which is set on every hit if the customer is logged in
            //the visitor is a logged in customer
            s.eVar58 = "Authenticated Customer"
        }else{
            s.eVar58 = "Customer"
        }
    }else{
        //the visitor is a prospect
        s.eVar58 = "Prospect"
    }
    //End Customer Identification
	
	s.plugins="";
	
}
s.doPlugins = s_doPlugins;
/*********************** CONVENIENCE FUNCTIONS **********************/
/* Optional functions to be called from the page as needed          */

function s_TrackingPageName_Event(sender, pageName, trackEvents, channel) {
    s.linkTrackVars = 'events,pageName,channel';
    s.linkTrackEvents = trackEvents;
    s.events = trackEvents;
    s.pageName = pageName;
    s.channel = channel;
    s.tl(sender, 'o', channel);
}

function s_TrackingApplyCoupon_Event(productsCouponsApplied) {
    if (productsCouponsApplied !== undefined && productsCouponsApplied.length > 0) {
        var s = s_gi(s_account);
        s.linkTrackVars = 'events,products';
        s.linkTrackEvents = 'event102';
        s.events = 'event102'
        s.products = ';' + productsCouponsApplied + ';;;;eVar62=available:selected';
        s.tl(this, 'o', 'add coupon in cart');

        s.events = null;
        productsCouponsApplied = '';
    }
}

function s_TrackingApplyCouponOnLogin_Event(productsCouponsAppliedOnLogin) {
    var s = s_gi(s_account);
    s.linkTrackVars = 'events,products';
    s.linkTrackEvents = 'event102';
    s.events = 'event102'
    s.products = productsCouponsAppliedOnLogin;
    s.tl(this, 'o', 'add coupon forced login');

    s.events = null;
    productsCouponsAppliedOnLogin = '';
}

function s_TrackingPageName(sender, pageName, channel) {
    s.pageName = pageName;
    s.channel = channel;
    s.linkTrackVars = 'pageName,channel';
    s.tl(sender, 'o', channel);
}

function s_genericTrackingProp19(sender, trackEvents, propVal, tlTitle) {
    s.linkTrackVars = 'events,prop19';
    s.linkTrackEvents = trackEvents;
    s.events = trackEvents;
    s.prop19 = propVal;
    s.tl(sender, 'o', tlTitle);
}

function s_trackBioAuthSetup(sender, trackEvents, tlTitle) {
    s.linkTrackVars = 'events';
    s.linkTrackEvents = trackEvents;
    s.events = trackEvents;
    s.tl(sender, 'o', tlTitle);
}

function s_genericTrackingLiveChat() {
    s.linkTrackVars = 'prop19';
    s.linkTrackEvents = 'None';
    s.prop19 = 'Live Chat';
    s.tl(true, 'o', 'Live Chat');
}

function s_trackFAQ(question) {
    s.linkTrackVars = 'prop19';
    s.linkTrackEvents = 'None';
    s.prop19 = 'FAQ: ' + s.pageName + ' ' + question;
    s.tl(true, 'o', 'FAQ');
}

function s_trackStarterCrust() {
    s.linkTrackVars = 'eVar11';
    s.linkTrackEvents = 'None';
    s.eVar11 = 'Starter Crust Contest';
    s.tl(true, 'o', 'Starter Crust Contest');
}

function s_trackTopNav(topNav) {
    s.linkTrackVars = 'prop15,prop32';
    s.prop15 = topNav;
    s.prop32 = s.pageName;
    s.tl(true, 'o', 'Top Nav Click');
}

function s_trackMyAccount(szname) {
    s.linkTrackVars = 'prop17';
    s.prop17 = topNav;
    s.tl(true, 'o', 'My Account');
}

function s_trackTryWith(szlocation, tlTitle) {
    s.linkTrackVars = 'events,eVar18';
    s.eVar18 = szlocation;
    s.linkTrackEvents = 'event25';
    s.events = 'event25';
    //s.tl(true, 'o', tlTitle);
}

function s_trackSubmitOrder(linkName) {
    s.linkTrackVars = 'prop19';
    s.prop19 = linkName;
    s.tl(true, 'o', 'Submit Order');
}

function s_trackLiveSmartPopup(productName) {
    s.linkTrackVars = 'prop23';
    s.prop23 = productName;
    s.tl(true, 'o', 'Nutritional Info Popup');
}

function s_trackLiveSmartGreenNav(linkName) {
    s.linkTrackVars = 'prop24';
    s.prop24 = linkName;
    s.tl(true, 'o', 'Healthy Living');
}

function s_trackProductDetailTabs(tabName) {
    s.linkTrackVars = 'prop25';
    s.prop25 = tabName;
    s.tl(true, 'o', 'Reviews');
}

function s_trackShares() {
    s.linkTrackVars = "events";
    s.linkTrackEvents = "event48";
    s.events = 'event48';
    s.tl(true, 'o', 'Product/Recipe Sharing');
}

function s_TrackMyMenuAddProduct() {
    s.linkTrackVars = "events";
    s.linkTrackEvents = "event52";
    s.events = 'event52';
    s.tl(true, 'o', 'My Menus Add Product');
}

function s_trackDRTVUpsell(overlay) {
    s.linkTrackVars = "events,pageName"; 
    s.linkTrackEvents = "event29";
    s.events = 'event29';
    s.pageName = overlay;
    s.tl(true, 'o', 'Upsell Overlay');
}

function s_FaceBookLogin() {
    s.linkTrackVars = "events";
    s.linkTrackEvents = "event69";
    s.events = 'event69';
    s.tl(true, 'o', 'Social Login');
}

function s_trackAddToCart(szEvent) {
    s.linkTrackVars = 'eVar3';
    s.eVar3 = szEvent;
    s.linkTrackEvents = 'None';
    s.tl(true, 'o', 'Add to Cart Finding Methods');
}

function s_trackRecommendationClicks(szName, szProductNumber) {
    s.linkTrackVars = 'events,products,eVar18';
    s.linkTrackEvents = 'event25';
    s.eVar18 = szName;
    s.events = 'event25';
    s.products = ';' + szProductNumber;
    s.tl(true, 'o', 'Recommendation Clicks');
}

function s_trackRatingsReviews(rating, reviewCount, ratingOnlyCount, buyAgain) {
    s.linkTrackVars = 'eVar9,eVar13,eVar14,eVar15';
    s.eVar9 = rating;
    s.eVar13 = reviewCount;
    s.eVar14 = ratingOnlyCount;
    s.eVar15 = buyAgain;
    s.linkTrackEvents = 'None';
    s.tl(true, 'o', 'BazaarVoice Reviews');
}

function s_trackLiveChat() {
    s.linkTrackVars = 'events,prop36'; 
    s.linkTrackEvents = 'event73';
    s.prop36 = 'Live Chat: ' + s.pageName;
    s.events = 'event73';
    s.tl(true, 'o', 'Live Chat Click');
}

function s_trackSpotlightProduct(szProductNumber, szProductName, szCategory) {
    s.linkTrackVars = 'events,eVar46'; 
    s.linkTrackEvents = 'event75';
    s.eVar46 = szCategory + ":" + szProductNumber + ":" + szProductName;
    s.events = 'event75';
    s.tl(true, 'o', 'Product Spotlight Click');
}

function s_trackCancelPendingOrder() {
    s.linkTrackVars = 'events';
    s.linkTrackEvents = 'event80';
    s.events = 'event80';
    s.tl(true, 'o', 'Cancel Order');
}

function s_trackModifyPendingOrder(szOrderNumber) {
    s.linkTrackVars = 'events,eVar20,eVar48';
    s.linkTrackEvents = 'event78';
    s.eVar20 = szOrderNumber;
    s.eVar48 = 'modified order';
    s.events = 'event78';
    s.tl(true, 'o', 'Modify Order');
}

function s_trackAutoReorderCheckoutLink(szProducts) {
    s.linkTrackVars = 'prop19,products';
    s.products = szProducts;
    s.prop19 = 'Auto Reorder:Checkout';
    s.tl(true, 'o', 'Modify Order');
}

function s_trackModifyPendingOrderUpdate() {
	s.linkTrackVars = 'events';
    s.linkTrackEvents = 'event79';
    s.events = 'event79';
    s.tl(true, 'o', 'Cancel Order');
}

function s_trackCheckoutStart() {
    s.linkTrackVars = 'events';
    s.linkTrackEvents = 'scCheckout';
    s.events = 'scCheckout';
    s.tl(true, 'o', 'Checkout');
    }

function s_trackModifyPendingOrderUpdateCombine() {
    s.linkTrackVars = 'prop19,events';
    s.linkTrackEvents = 'event93';
    s.events = 'event93';
    s.prop19 = 'Combine Orders: Yes';
    s.tl(true, 'o', 'Combined Order');
}

function s_trackProductFilters(filter) {
    s.linkTrackVars = 'prop43,eVar47';
    s.linkTrackEvents = 'event97';
    s.prop43 = s.eVar47 = filter;
    s.events = 'event97';
    s.tl(this,'o','product filter');
}

function s_TrackSameDayDeliveryProductRemoved(szProducts) {
    s.linkTrackVars = 'events,products';
    s.linkTrackEvents = 'scRemove';
    s.events = 'scRemove';
    s.products = ';' + szProducts + ';;;;eVar74=inventory_modal';
    s.tl(this, 'o', 'Remove from Cart on Inventory Modal');
}

/************************** PLUGINS SECTION *************************/
/* You may insert any plugins you wish to use here.                 */
/* Adobe Consulting Plugin: getPercentPageViewed v3.0 (Requires AppMeasurement; also the handlePPVevents helper function and p_fo plugin) */
s.getPercentPageViewed=function(pid,ch){var s=this,a=s.c_r("s_ppv");a=-1<a.indexOf(",")?a.split(","):[];a[0]=s.unescape(a[0]);pid=pid?pid:s.pageName?s.pageName:document.location.href;s.ppvChange=ch?ch:!0;if("undefined"===typeof s.linkType||"o"!==s.linkType)s.ppvID&&s.ppvID===pid||(s.ppvID=pid,s.c_w("s_ppv",""),s.handlePPVevents()),s.p_fo("s_gppvLoad")&&window.addEventListener&&(window.addEventListener("load",s.handlePPVevents,!1),window.addEventListener("click",s.handlePPVevents,
!1),window.addEventListener("scroll",s.handlePPVevents,!1),window.addEventListener("resize",s.handlePPVevents,!1)),s._ppvPreviousPage=a[0]?a[0]:"",s._ppvHighestPercentViewed=a[1]?a[1]:"",s._ppvInitialPercentViewed=a[2]?a[2]:"",s._ppvHighestPixelsSeen=a[3]?a[3]:""};

/* Adobe Consulting Plugin: handlePPVEvents helper function v3.0 (for getPercentPageViewed Plugin) */
s.handlePPVevents=function(){if(s_c_il){for(var b=0,d=s_c_il.length;b<d;b++)if("undefined"!=typeof s_c_il[b]&&s_c_il[b]._c&&"s_c"==s_c_il[b]._c){var a=s_c_il[b];break}if(a&&a.ppvID){var f=Math.max(Math.max(document.body.scrollHeight,document.documentElement.scrollHeight),Math.max(document.body.offsetHeight,document.documentElement.offsetHeight),Math.max(document.body.clientHeight,document.documentElement.clientHeight));b=(window.pageYOffset||window.document.documentElement.scrollTop||window.document.body.scrollTop)+
(window.innerHeight||document.documentElement.clientHeight||document.body.clientHeight);d=Math.min(Math.round(b/f*100),100);var e="";!a.c_r("s_tp")||a.unescape(a.c_r("s_ppv").split(",")[0])!==a.ppvID||1==a.ppvChange&&a.c_r("s_tp")&&f!=a.c_r("s_tp")?(a.c_w("s_tp",f),a.c_w("s_ppv","")):e=a.c_r("s_ppv");var c=e&&-1<e.indexOf(",")?e.split(",",4):[];f=0<c.length?c[0]:escape(a.ppvID);var g=1<c.length?parseInt(c[1]):d,h=2<c.length?parseInt(c[2]):d;c=3<c.length?parseInt(c[3]):b;0<d&&(e=f+","+(d>g?d:g)+","+
h+","+(b>c?b:c));a.c_w("s_ppv",e)}}};

/* Adobe Consulting Plugin: p_fo (pageFirstOnly) v2.0 (Requires AppMeasurement) */
s.p_fo=function(on){var s=this;s.__fo||(s.__fo={});if(s.__fo[on])return!1;s.__fo[on]={};return!0};

/* Adobe Consulting Plugin: getNewRepeat v2.0, requires AppMeasurement */
s.getNewRepeat=function(d){var b=new Date,c=b.getTime();d=d?d:30;var z="s_nr"+d;b.setTime(c+864E5*d);d=this.c_r(z);if(0==d.length)return this.c_w(z,c+"-New",b),"New";d=d.split("-");if(18E4>c-d[0]&&"New"===d[1])return this.c_w(z,c+"-New",b),"New";this.c_w(z,c+"-Repeat",b);return"Repeat"};

/* Adobe Consulting Plugin: getTimeParting v5.0 (does NOT require AppMeasurement) */
var getTimeParting=function(tza){var a=new Date;tza=parseFloat(tza?tza:0);Date.prototype.stdTimezoneOffset=function(){var a=new Date(this.getFullYear(),0,1),tza=new Date(this.getFullYear(),6,1);return Math.max(a.getTimezoneOffset(),tza.getTimezoneOffset())};Date.prototype.isDaylightSavings=function(){return this.getTimezoneOffset()<this.stdTimezoneOffset()};a.isDaylightSavings()&&(tza+=1);a=a.getTime()+6E4*a.getTimezoneOffset();a=new Date(a+36E5*tza);var c=a.getHours();var d=a.getMinutes();d=10>d?"0"+d:d;var e="AM";
12<=c&&(e="PM",c-=12);0==c&&(c=12);tza=10>a.getDate()?"0"+a.getDate():a.getDate();c=c+":"+d+" "+e;return"year="+a.getFullYear()+" | month="+"January February March April May June July August September October November December".split(" ")[a.getMonth()]+" | date="+tza+" | day="+"Sunday Monday Tuesday Wednesday Thursday Friday Saturday".split(" ")[a.getDay()]+" | time="+c};

/* Adobe Consulting Plugin: crossVisitParticipation v2.5 (requires AppMeasurement; also inList v2.0 and join *v2.0* plugins) */
s.crossVisitParticipation=function(vts,cn,ed,ns,de,ce,sd){vts="undefined"===typeof vts?"":vts;cn="undefined"===typeof cn?"s_cvp":cn;ed="undefined"===typeof ed?90:ed;ns="undefined"===typeof ns?5:ns;de="undefined"===typeof de?" > ":de;sd="undefined"===typeof sd?!1:sd;var m,b=[],q=[],r=[],n=0,d=0,p=new Date,t=new Date;this.events&&this.inList(this.events,"undefined"===typeof ce?"":ce)&&(m=!0);if(""===vts)return m&&this.c_w(cn,""),"";vts=encodeURIComponent(vts);if((ce=this.c_r(cn))&&""!==ce)for(b=ce.split("],["),d=b.length,ce=0;ce<d;ce++){var f=
b[ce];f=this.replace(f,"[","");f=this.replace(f,"]","");f=this.replace(f,'"',"");b[ce]=f.split(",")}!1===sd&&0<d&&b[d-1][0]===vts?b[d-1]=[vts,(new Date).getTime()]:b[d]=[vts,(new Date).getTime()];d=b.length;for(ce=0>d-ns?0:d-ns;ce<d;ce++)vts=Math.round((t.getTime()-b[ce][1])/864E5),vts<ed&&(r[n]=decodeURIComponent(b[ce][0]),q[n]=[b[ce][0],b[ce][1]],n++);m?this.c_w(cn,""):(p.setFullYear(p.getFullYear()+5),this.c_w(cn,this.join(q,"[","]",",",'"'),p));return r.join(de)};

/* Adobe Consulting Plugin: inList v2.0 (requires AppMeasurement) */
s.inList=function(lv,vtc,d,cc){if("string"!==typeof vtc)return!1;if("string"===typeof lv)lv=lv.split(d?d:",");else if("object"!==typeof lv)return!1;d=0;for(var e=lv.length;d<e;d++)if(cc&&vtc===lv[d]||!cc&&vtc.toLowerCase()===lv[d].toLowerCase())return!0;return!1};

/* Adobe Consulting Plugin: join v2.0 (requires AppMeasurement) */
s.join = function(ar,fr,ba,de,wr){var f="";fr=fr?fr:"";ba=ba?ba:"";de=de?de:"";wr=wr?wr:"";for(var b=0,h=ar.length;b<h;b++)f="object"==typeof ar[b]?f+this.join(ar[b],fr,ba,de,wr):f+(wr+ar[b]+wr),b<ar.length-1&&(f+=de);return fr+f+ba};

/* Adobe Consulting Plugin: channelManager v4.0 - Requires AppMeasurement and CONSULTING-BUILT getQueryParam v3.0/pt v2.0Plugins (i.e. relying on Util.getQueryParam will NOT work) */
s.channelManager=function(qsp,tbl,con){var s=this,d=!0,p=new Date;p.setTime(p.getTime()+18E5);if(s._channel)return"";var g="n/a",l="n/a",u="n/a",v="n/a",w="n/a",q="n/a";s.c_r("s_tbm")&&(d=!1);tbl&&s.c_r("s_tbm"+tbl.toString())&&(d=!1);s.c_w("s_tbm",!0,p);var h=s.referrer?s.referrer:document.referrer;"Typed/Bookmarked"===h&&(h="");var c=!1;if(h){c=!0;var k=h.split("/")[2].split("?")[0].toLowerCase(),b=s.linkInternalFilters.toLowerCase().split(",");lowerLinkInternalFiltersLength=b.length;for(a=
0;a<lowerLinkInternalFiltersLength;a++)if(-1<k.indexOf(b[a])){c=!1;break}}if(c&&(v=h,w=k,g="Other Natural Referrers",l=g+": "+k,s._channelSEList))for(var e=s._channelSEList.split(">"),m=e.length,n,t,a=0;a<m;a++){b=e[a];n=b.split("|");t=n[1].split(",");searchEngineDomainsLength=t.length;for(b=0;b<searchEngineDomainsLength;b++)if(-1<k.indexOf(t[b].toLowerCase())){q=n[0];break}if("n/a"!==q)break}e="";if(qsp){b=qsp.split(",");qsp=b.length;for(a=0;a<qsp&&!(e=s.getQueryParam(b[a]));a++);e&&(u=l=e,g="n/a"!==
q?"Paid Search":"Unknown Paid Channel")}e||"n/a"===q||(g="Natural Search",l=g+": "+q);!d||h||e||(v=w=l=g="Typed/Bookmarked");if(s._channelDomain&&c)for(qsp=!1,d=s._channelDomain.split(">"),h=d.length,a=0;a<h;a++){c=d[a]?d[a].split("|"):"";m=c[1]?c[1].split(","):"";n=m.length;for(b=0;b<n;b++)if(t=m[b].toLowerCase(),-1<("/"+k).indexOf(t)){g=c[0];l=e?l:g+": "+k;qsp=!0;break}if(qsp)break}if(s._channelParameter)for(qsp=!1,d=s._channelParameter.split(">"),h=d.length,a=0;a<h;a++){c=d[a]?d[a].split("|"):
"";n=c[1]?c[1].split(","):"";b=0;for(m=n.length;b<m;b++)if(s.getQueryParam(n[b])){g=c[0];l=e?l:g+": "+k;qsp=!0;break}if(qsp)break}if(s._channelPattern&&e)for(qsp=!1,d=s._channelPattern.split(">"),h=d.length,a=0;a<h;a++){c=d[a]?d[a].split("|"):"";k=c[1]?c[1].split(","):"";b=0;for(m=k.length;b<m;b++)if(!con&&0==e.toLowerCase().indexOf(k[b].toLowerCase())||con&&-1<e.toLowerCase().indexOf(k[b].toLowerCase())){g=c[0];u=l=e;qsp=!0;break}if(qsp)break}"n/a"!==g?(s._channel=g,s._campaign=l,s._campaignID=
u,s._referrer=v,s._referringDomain=w,s._searchEngine=q,"Typed/Bookmarked"!==s._channel&&tbl&&(p.setTime(p.getTime()+864E5*Number(tbl)),s.c_w("s_tbm"+tbl.toString(),!0,p))):s._channel=s._campaign=s._campaignID=s._referrer=s._referringDomain=s._searchEngine=""};

/* Adobe Consulting Plugin: getQueryParam v3.0 (with findParameterValue/getParameterValue helper functions; requires AppMeasurement and pt plugin) */
s.getQueryParam=function(qsp,de,url){var e="";qsp=qsp.split(",");var g=qsp.length;de=de?de:"";url=(url?url:this.pageURL?this.pageURL:document.urlToCheck)+"";if((4<de.length||-1<de.indexOf("="))&&url&&4>url.length){var a=de;de=url;url=a}for(var f=0;f<g;f++)(a=this.findParameterValue(qsp[f],url))&&(a=-1<a.indexOf("#")?a.substring(0,a.indexOf("#")):a),a&&(e+=e?de+a:a);return e};
s.findParameterValue=function(c,a){var b="";-1<a.indexOf("#")&&(-1<a.indexOf("?")?a.indexOf("?")>a.indexOf("#")?(a=a.split("?").join("&"),a=a.split("#").join("?")):a=a.split("#").join("&"):a=a.split("#").join("?"));searchStartLocation=a.indexOf("?");c&&-1<searchStartLocation&&(b=a.substring(searchStartLocation+1),b=this.pt(b,"&","getParameterValue",c));return b};
s.getParameterValue=function(b,c){if(b){var a=b.split("="),d=a[0];a=a[1]?a[1]:!0;if(c.toLowerCase()==d.toLowerCase())return this.unescape(a)}return""};

/* Adobe Consulting Plugin: pt v2.0 (requires AppMeasurement) */
s.pt=function(l,d,cf,fa){l=l.split(d?d:",");for(var c=0,f=l.length;c<f;c++)if(d=this[cf](l[c],fa))return d;return""};

/* channelManager SearchEngineList (Recommeded for ALL Deployments) */
s._channelSEList="Google|.google.,googlesyndication.com,.googleadservices.com>Google Search App|googlequicksearchbox>Bing|bing.com>Yahoo!|yahoo.com,yahoo.co.jp>Naver|naver.com,search.naver.com>Yandex.ru|yandex>DuckDuckGo|duckduckgo.com>Daum|daum.net,search.daum.net>Baidu|baidu.com>MyWay.com|myway.com>Ecosia|ecosia.org>Ask|ask.jp,ask.co>DogPile|dogpile.com>sm.cn|sm.cn>sogou.com|sogou.com>Haosou|so.com>Seznam.cz|Seznam.cz>AOL|search.aol.,suche.aolsvc.de>AltaVista|altavista.co,altavista.de>MyWebSearch|.mywebsearch.com>WebCrawler|webcrawler.com>Wow|wow.com>InfoSpace|infospace.com>Blekko|blekko.com>Docomo|docomo.ne.jp"

/* Adobe Consulting Plugin: Cookie Combining Utility v2.0, requires AppMeasurement */
if("undefined"===typeof cookieCombiningUtility){var cookieCombiningUtility=!0,removeExpiredCookies=function(){var b=this.c_rr("s_pers"),d=(new Date).getTime(),a="";if(!b)return"";var c=b.split(";");for(var e=0,h=c.length;e<h;e++)(b=c[e].match(/\|([0-9]+)$/))&&parseInt(b[1])>=d&&(a+=c[e]+";");return a},cookieRead=function(b){var d=this.c_rr(b),a=this.removeExpiredCookies();if(d)return d;b=this.escape(b);d=a.indexOf(" "+b+"=");a=0>d?this.c_rr("s_sess"):a;d=a.indexOf(" "+b+"=");if(0>d)return"";var c=
a.indexOf("|",d);var e=a.indexOf(";",d);c=0<c?c:e;return this.unescape(a.substring(d+2+b.length,0>c?a.length:c))},cookieWrite=function(b,d,a){var c=new Date(0),e=0,h=!1,k=0;if("s_sq"===b)this.c_wr(b,d);else{this.c_wr(b,"",c);b=this.escape(b);var f=this.removeExpiredCookies();c=f.indexOf(" "+b+"=");-1<c&&(f=f.substring(0,c)+f.substring(f.indexOf(";",c)+1),h=!0);var g=this.c_rr("s_sess");c=g.indexOf(" "+b+"=");-1<c&&(g=g.substring(0,c)+g.substring(g.indexOf(";",c)+1),k=!0);c=new Date;if(a){if(1===a){a=
new Date;var l=a.getYear();a.setYear(l+5+(1900>l?1900:0))}a.getTime()>c.getTime()&&(f+=" "+b+"="+this.escape(d)+"|"+a.getTime()+";",h=!0)}else g+=" "+b+"="+this.escape(d)+";",k=!0;g=g.replace(/%00/g,"");f=f.replace(/%00/g,"");k&&this.c_wr("s_sess",g,0);if(h){for(a=f;a&&-1<a.indexOf(";");)h=parseInt(a.substring(a.indexOf("|")+1,a.indexOf(";"))),a=a.substring(a.indexOf(";")+1),e=e<h?h:e;c.setTime(e);this.c_wr("s_pers",f,c)}return d===this.c_r(this.unescape(b))}},cookieDelete=function(b){this.c_w(b,
"",new Date(0))};
s.ccuSetup=function(){var s=this;s.c_wr||(s.c_wr=s.c_w);s.c_rr||(s.c_rr=s.c_r);s.removeExpiredCookies=removeExpiredCookies;s.c_r=s.cookieRead=cookieRead;s.c_w=s.cookieWrite=cookieWrite;s.c_d=s.cookieDelete=cookieDelete};
s.ccuSetup()};

/* Adobe Consulting Plugin: apl (appendToList) v3.1 (requires AppMeasurement and inList v2.0) */
s.apl=function(lv,vta,d1,d2,cc){d1=d1?d1:",";d2=d2?d2:d1;if("undefined"===typeof this.inList)return console.log("Adobe Analytics: Problem with apl plugin - inList helper function not available"),lv;if("undefined"!==typeof lv&&"string"!==typeof lv)return console.log("Adobe Analytics: Problem with apl plugin - first passed-in argument is not lv string object"),"";if("string"!==typeof vta)return lv;vta=vta.split(",");for(var g=vta.length,d=0;d<g;d++)this.inList(lv,vta[d],d1,cc)||(lv=lv?lv+d2+vta[d]:vta[d]);return lv};

/* Adobe Consulting Plugin: getValOnce v2.0, requires AppMeasurement */
s.getValOnce=function(vtc,cn,et,ep){cn=cn?cn:"s_gvo";et=et?et:0;ep="m"===ep?6E4:864E5;if(vtc&&vtc!==this.c_r(cn)){var e=new Date;e.setTime(e.getTime()+et*ep);this.c_w(cn,vtc,0===et?0:e);return vtc}return""};

/* Adobe Consulting Plugin: apl (appendToList) v3.1 (requires AppMeasurement and inList v2.0) */
s.apl=function(lv,vta,d1,d2,cc){d1=d1?d1:",";d2=d2?d2:d1;if("undefined"===typeof this.inList)return console.log("Adobe Analytics: Problem with apl plugin - inList helper function not available"),lv;if("undefined"!==typeof lv&&"string"!==typeof lv)return console.log("Adobe Analytics: Problem with apl plugin - first passed-in argument is not lv string object"),"";if("string"!==typeof vta)return lv;vta=vta.split(",");for(var g=vta.length,d=0;d<g;d++)this.inList(lv,vta[d],d1,cc)||(lv=lv?lv+d2+vta[d]:vta[d]);return lv};

/* Adobe Consulting Plugin: getTimeToComplete v3.0 (Requires AppMeasurement) */
s.getTimeToComplete=function(sos,cn,exp){var c=5,e=new Date;sos=sos?sos.toLowerCase():"start";cn=cn?cn:"s_gttc";exp=exp?exp:0;var f=this.c_r(cn);if("start"===sos&&!f)this.c_w(cn,e.getTime(),exp?new Date(e.getTime()+864E5*exp):0);else if("stop"===sos&&f){var g=Math.round((e.getTime()-f)/1E3);86400<g?(sos=86400,exp="days"):3600<g?(sos=3600,exp="hours"):60<g?(c=2,sos=60,exp="minutes"):(sos=1,exp="seconds");c=Math.round(g*c/sos)/c+" "+exp;0===c.indexOf("1 ")&&(c=c.substring(0,c.length-1));this.c_w(cn,"",new Date(e.getTime()-f));return c}};

/* Plugin: getPreviousValue v2.0, requires AppMeasurement */
s.getPreviousValue=function(vtc,cn,el){var s=this,g="",a=!0;cn=cn?cn:"s_gpv";if(el){a=!1;el=el.split(",");for(var h=s.events?s.events.split(","):"",e=0,k=el.length;e<k;e++){for(var f=0,l=h.length;f<l;f++)if(el[e]===h[f]){a=!0;break}if(!0===a)break}}!0===a&&(a=new Date,a.setTime(a.getTime()+18E5),s.c_r(cn)&&(g=s.c_r(cn)),vtc?s.c_w(cn,vtc,a):s.c_w(cn,"no previous value",a));return g};

/*
 * Partner Plugin: Gigya v3 (BEGIN)
 */
var gigya_omniture_conf = { 
    linkName: 'Gigya Action', 
  eventMap : [ 
    {
      gigEvent:"login",
      omtrEvents:["event70"],
      mapVars:["eVar41=user.loginProvider", "=getAge()", "=getGender()", "=getiRank()"] 
    }, 
    {
      gigEvent:"sendDone--DISABLED",
      omtrEvents:[""],
      mapVars:["=providers","products"] 
    }, 
    {
      gigEvent:"commentSubmitted--DISABLED",
      omtrEvents:[""], 
      mapVars:["=getCommentProviders()","products"] 
    },
    {
      gigEvent:"reactionClicked--DISABLED",
      omtrEvents:[""], 
      mapVars:["=reaction.ID","products"] 
    } 	
  ],
	getCommentProviders:function(evt) {			
		var g=evt['providerPostIDs']
		if(g&&typeof(g)=="object"){  
			var b = "",f;
			for (f in g) b += (b ? "," : "") + f;
			return b;
		}
		return '';
	},
	getAge:function(evt) {		
		var a=evt.user['age']
		if(typeof(a)=="number"&&a>0){  
			return a;
		}
		return '?';
	},
	getGender:function(evt) {			
		var g=evt.user['gender']
		if(typeof(g)=="string"&&g.length>0){  
			return g;
		}
		return '?';
	},
	getiRank:function(evt) {		
		if(typeof(evt.user['iRank'])=="string"){
		  var r = parseFloat(evt.user['iRank']).toFixed(0);
		  if(r >= 0.0001) return r;
		}
		return '?';
	}
}
/*
 * Partner Plugin: Gigya v3 (END)
 */


/* WARNING: Changing any of the below variables will cause drastic
changes to how your visitor data is collected.  Changes should only be
made when instructed to do so by your account manager.*/
s.visitorNamespace = "schwans"
s.trackingServer = "metrics.yelloh.com"
s.trackingServerSecure = "metrics.yelloh.com"
s.dc = "d1"

/************************** MODULE SECTION *************************/
/* AppMeasurement Integrate Module */
function AppMeasurement_Module_Integrate(l){var c=this;c.s=l;var e=window;e.s_c_in||(e.s_c_il=[],e.s_c_in=0);c._il=e.s_c_il;c._in=e.s_c_in;c._il[c._in]=c;e.s_c_in++;c._c="s_m";c.list=[];c.add=function(d,b){var a;b||(b="s_Integrate_"+d);e[b]||(e[b]={});a=c[d]=e[b];a.a=d;a.e=c;a._c=0;a._d=0;void 0==a.disable&&(a.disable=0);a.get=function(b,d){var f=document,h=f.getElementsByTagName("HEAD"),k;if(!a.disable&&(d||(v="s_"+c._in+"_Integrate_"+a.a+"_get_"+a._c),a._c++,a.VAR=v,a.CALLBACK="s_c_il["+c._in+"]."+
a.a+".callback",a.delay(),h=h&&0<h.length?h[0]:f.body))try{k=f.createElement("SCRIPT"),k.type="text/javascript",k.setAttribute("async","async"),k.src=c.c(a,b),0>b.indexOf("[CALLBACK]")&&(k.onload=k.onreadystatechange=function(){a.callback(e[v])}),h.firstChild?h.insertBefore(k,h.firstChild):h.appendChild(k)}catch(l){}};a.callback=function(b){var c;if(b)for(c in b)Object.prototype[c]||(a[c]=b[c]);a.ready()};a.beacon=function(b){var d="s_i_"+c._in+"_Integrate_"+a.a+"_"+a._c;a.disable||(a._c++,d=e[d]=
new Image,d.src=c.c(a,b))};a.script=function(b){a.get(b,1)};a.delay=function(){a._d++};a.ready=function(){a._d--;a.disable||l.delayReady()};c.list.push(d)};c._g=function(d){var b,a=(d?"use":"set")+"Vars";for(d=0;d<c.list.length;d++)if((b=c[c.list[d]])&&!b.disable&&b[a])try{b[a](l,b)}catch(e){}};c._t=function(){c._g(1)};c._d=function(){var d,b;for(d=0;d<c.list.length;d++)if((b=c[c.list[d]])&&!b.disable&&0<b._d)return 1;return 0};c.c=function(c,b){var a,e,g,f;"http"!=b.toLowerCase().substring(0,4)&&
(b="http://"+b);l.ssl&&(b=l.replace(b,"http:","https:"));c.RAND=Math.floor(1E13*Math.random());for(a=0;0<=a;)a=b.indexOf("[",a),0<=a&&(e=b.indexOf("]",a),e>a&&(g=b.substring(a+1,e),2<g.length&&"s."==g.substring(0,2)?(f=l[g.substring(2)])||(f=""):(f=""+c[g],f!=c[g]&&parseFloat(f)!=c[g]&&(g=0)),g&&(b=b.substring(0,a)+encodeURIComponent(f)+b.substring(e+1)),a=e));return b}}
/* AppMeasurement Media Module */
function AppMeasurement_Module_Media(q){var b=this;b.s=q;q=window;q.s_c_in||(q.s_c_il=[],q.s_c_in=0);b._il=q.s_c_il;b._in=q.s_c_in;b._il[b._in]=b;q.s_c_in++;b._c="s_m";b.list=[];b.open=function(d,c,e,k){var f={},a=new Date,l="",g;c||(c=-1);if(d&&e){b.list||(b.list={});b.list[d]&&b.close(d);k&&k.id&&(l=k.id);if(l)for(g in b.list)!Object.prototype[g]&&b.list[g]&&b.list[g].R==l&&b.close(b.list[g].name);f.name=d;f.length=c;f.offset=0;f.e=0;f.playerName=b.playerName?b.playerName:e;f.R=l;f.C=0;f.a=0;f.timestamp=
Math.floor(a.getTime()/1E3);f.k=0;f.u=f.timestamp;f.c=-1;f.n="";f.g=-1;f.D=0;f.I={};f.G=0;f.m=0;f.f="";f.B=0;f.L=0;f.A=0;f.F=0;f.l=!1;f.v="";f.J="";f.K=0;f.r=!1;f.H="";f.complete=0;f.Q=0;f.p=0;f.q=0;b.list[d]=f}};b.openAd=function(d,c,e,k,f,a,l,g){var h={};b.open(d,c,e,g);if(h=b.list[d])h.l=!0,h.v=k,h.J=f,h.K=a,h.H=l};b.M=function(d){var c=b.list[d];b.list[d]=0;c&&c.monitor&&clearTimeout(c.monitor.interval)};b.close=function(d){b.i(d,0,-1)};b.play=function(d,c,e,k){var f=b.i(d,1,c,e,k);f&&!f.monitor&&
(f.monitor={},f.monitor.update=function(){1==f.k&&b.i(f.name,3,-1);f.monitor.interval=setTimeout(f.monitor.update,1E3)},f.monitor.update())};b.click=function(d,c){b.i(d,7,c)};b.complete=function(d,c){b.i(d,5,c)};b.stop=function(d,c){b.i(d,2,c)};b.track=function(d){b.i(d,4,-1)};b.P=function(d,c){var e="a.media.",k=d.linkTrackVars,f=d.linkTrackEvents,a="m_i",l,g=d.contextData,h;c.l&&(e+="ad.",c.v&&(g["a.media.name"]=c.v,g[e+"pod"]=c.J,g[e+"podPosition"]=c.K),c.G||(g[e+"CPM"]=c.H));c.r&&(g[e+"clicked"]=
!0,c.r=!1);g["a.contentType"]="video"+(c.l?"Ad":"");g["a.media.channel"]=b.channel;g[e+"name"]=c.name;g[e+"playerName"]=c.playerName;0<c.length&&(g[e+"length"]=c.length);g[e+"timePlayed"]=Math.floor(c.a);0<Math.floor(c.a)&&(g[e+"timePlayed"]=Math.floor(c.a));c.G||(g[e+"view"]=!0,a="m_s",b.Heartbeat&&b.Heartbeat.enabled&&(a=c.l?b.__primetime?"mspa_s":"msa_s":b.__primetime?"msp_s":"ms_s"),c.G=1);c.f&&(g[e+"segmentNum"]=c.m,g[e+"segment"]=c.f,0<c.B&&(g[e+"segmentLength"]=c.B),c.A&&0<c.a&&(g[e+"segmentView"]=
!0));!c.Q&&c.complete&&(g[e+"complete"]=!0,c.S=1);0<c.p&&(g[e+"milestone"]=c.p);0<c.q&&(g[e+"offsetMilestone"]=c.q);if(k)for(h in g)Object.prototype[h]||(k+=",contextData."+h);l=g["a.contentType"];d.pe=a;d.pev3=l;var q,s;if(b.contextDataMapping)for(h in d.events2||(d.events2=""),k&&(k+=",events"),b.contextDataMapping)if(!Object.prototype[h]){a=h.length>e.length&&h.substring(0,e.length)==e?h.substring(e.length):"";l=b.contextDataMapping[h];if("string"==typeof l)for(q=l.split(","),s=0;s<q.length;s++)l=
q[s],"a.contentType"==h?(k&&(k+=","+l),d[l]=g[h]):"view"==a||"segmentView"==a||"clicked"==a||"complete"==a||"timePlayed"==a||"CPM"==a?(f&&(f+=","+l),"timePlayed"==a||"CPM"==a?g[h]&&(d.events2+=(d.events2?",":"")+l+"="+g[h]):g[h]&&(d.events2+=(d.events2?",":"")+l)):"segment"==a&&g[h+"Num"]?(k&&(k+=","+l),d[l]=g[h+"Num"]+":"+g[h]):(k&&(k+=","+l),d[l]=g[h]);else if("milestones"==a||"offsetMilestones"==a)h=h.substring(0,h.length-1),g[h]&&b.contextDataMapping[h+"s"][g[h]]&&(f&&(f+=","+b.contextDataMapping[h+
"s"][g[h]]),d.events2+=(d.events2?",":"")+b.contextDataMapping[h+"s"][g[h]]);g[h]&&(g[h]=0);"segment"==a&&g[h+"Num"]&&(g[h+"Num"]=0)}d.linkTrackVars=k;d.linkTrackEvents=f};b.i=function(d,c,e,k,f){var a={},l=(new Date).getTime()/1E3,g,h,q=b.trackVars,s=b.trackEvents,t=b.trackSeconds,u=b.trackMilestones,v=b.trackOffsetMilestones,w=b.segmentByMilestones,x=b.segmentByOffsetMilestones,p,n,r=1,m={},y;b.channel||(b.channel=b.s.w.location.hostname);if(a=d&&b.list&&b.list[d]?b.list[d]:0)if(a.l&&(t=b.adTrackSeconds,
u=b.adTrackMilestones,v=b.adTrackOffsetMilestones,w=b.adSegmentByMilestones,x=b.adSegmentByOffsetMilestones),0>e&&(e=1==a.k&&0<a.u?l-a.u+a.c:a.c),0<a.length&&(e=e<a.length?e:a.length),0>e&&(e=0),a.offset=e,0<a.length&&(a.e=a.offset/a.length*100,a.e=100<a.e?100:a.e),0>a.c&&(a.c=e),y=a.D,m.name=d,m.ad=a.l,m.length=a.length,m.openTime=new Date,m.openTime.setTime(1E3*a.timestamp),m.offset=a.offset,m.percent=a.e,m.playerName=a.playerName,m.mediaEvent=0>a.g?"OPEN":1==c?"PLAY":2==c?"STOP":3==c?"MONITOR":
4==c?"TRACK":5==c?"COMPLETE":7==c?"CLICK":"CLOSE",2<c||c!=a.k&&(2!=c||1==a.k)){f||(k=a.m,f=a.f);if(c){1==c&&(a.c=e);if((3>=c||5<=c)&&0<=a.g&&(r=!1,q=s="None",a.g!=e)){h=a.g;h>e&&(h=a.c,h>e&&(h=e));p=u?u.split(","):0;if(0<a.length&&p&&e>=h)for(n=0;n<p.length;n++)(g=p[n]?parseFloat(""+p[n]):0)&&h/a.length*100<g&&a.e>=g&&(r=!0,n=p.length,m.mediaEvent="MILESTONE",a.p=m.milestone=g);if((p=v?v.split(","):0)&&e>=h)for(n=0;n<p.length;n++)(g=p[n]?parseFloat(""+p[n]):0)&&h<g&&e>=g&&(r=!0,n=p.length,m.mediaEvent=
"OFFSET_MILESTONE",a.q=m.offsetMilestone=g)}if(a.L||!f){if(w&&u&&0<a.length){if(p=u.split(","))for(p.push("100"),n=h=0;n<p.length;n++)if(g=p[n]?parseFloat(""+p[n]):0)a.e<g&&(k=n+1,f="M:"+h+"-"+g,n=p.length),h=g}else if(x&&v&&(p=v.split(",")))for(p.push(""+(0<a.length?a.length:"E")),n=h=0;n<p.length;n++)if((g=p[n]?parseFloat(""+p[n]):0)||"E"==p[n]){if(e<g||"E"==p[n])k=n+1,f="O:"+h+"-"+g,n=p.length;h=g}f&&(a.L=!0)}(f||a.f)&&f!=a.f&&(a.F=!0,a.f||(a.m=k,a.f=f),0<=a.g&&(r=!0));(2<=c||100<=a.e)&&a.c<e&&
(a.C+=e-a.c,a.a+=e-a.c);if(2>=c||3==c&&!a.k)a.n+=(1==c||3==c?"S":"E")+Math.floor(e),a.k=3==c?1:c;!r&&0<=a.g&&3>=c&&(t=t?t:0)&&a.a>=t&&(r=!0,m.mediaEvent="SECONDS");a.u=l;a.c=e}if(!c||3>=c&&100<=a.e)2!=a.k&&(a.n+="E"+Math.floor(e)),c=0,q=s="None",m.mediaEvent="CLOSE";7==c&&(r=m.clicked=a.r=!0);if(5==c||b.completeByCloseOffset&&(!c||100<=a.e)&&0<a.length&&e>=a.length-b.completeCloseOffsetThreshold)r=m.complete=a.complete=!0;l=m.mediaEvent;"MILESTONE"==l?l+="_"+m.milestone:"OFFSET_MILESTONE"==l&&(l+=
"_"+m.offsetMilestone);a.I[l]?m.eventFirstTime=!1:(m.eventFirstTime=!0,a.I[l]=1);m.event=m.mediaEvent;m.timePlayed=a.C;m.segmentNum=a.m;m.segment=a.f;m.segmentLength=a.B;b.monitor&&4!=c&&b.monitor(b.s,m);b.Heartbeat&&b.Heartbeat.enabled&&0<=a.g&&(r=!1);0==c&&b.M(d);r&&a.D==y&&(d={contextData:{}},d.linkTrackVars=q,d.linkTrackEvents=s,d.linkTrackVars||(d.linkTrackVars=""),d.linkTrackEvents||(d.linkTrackEvents=""),b.P(d,a),d.linkTrackVars||(d["!linkTrackVars"]=1),d.linkTrackEvents||(d["!linkTrackEvents"]=
1),b.s.track(d),a.F?(a.m=k,a.f=f,a.A=!0,a.F=!1):0<a.a&&(a.A=!1),a.n="",a.p=a.q=0,a.a-=Math.floor(a.a),a.g=e,a.D++)}return a};b.O=function(d,c,e,k,f){var a=0;if(d&&(!b.autoTrackMediaLengthRequired||c&&0<c)){if(b.list&&b.list[d])a=1;else if(1==e||3==e)b.open(d,c,"HTML5 Video",f),a=1;a&&b.i(d,e,k,-1,0)}};b.attach=function(d){var c,e,k;d&&d.tagName&&"VIDEO"==d.tagName.toUpperCase()&&(b.o||(b.o=function(c,a,d){var e,h;b.autoTrack&&(e=c.currentSrc,(h=c.duration)||(h=-1),0>d&&(d=c.currentTime),b.O(e,h,a,
d,c))}),c=function(){b.o(d,1,-1)},e=function(){b.o(d,1,-1)},b.j(d,"play",c),b.j(d,"pause",e),b.j(d,"seeking",e),b.j(d,"seeked",c),b.j(d,"ended",function(){b.o(d,0,-1)}),b.j(d,"timeupdate",c),k=function(){d.paused||d.ended||d.seeking||b.o(d,3,-1);setTimeout(k,1E3)},k())};b.j=function(b,c,e){b.attachEvent?b.attachEvent("on"+c,e):b.addEventListener&&b.addEventListener(c,e,!1)};void 0==b.completeByCloseOffset&&(b.completeByCloseOffset=1);void 0==b.completeCloseOffsetThreshold&&(b.completeCloseOffsetThreshold=
1);b.Heartbeat={};b.N=function(){var d,c;if(b.autoTrack&&(d=b.s.d.getElementsByTagName("VIDEO")))for(c=0;c<d.length;c++)b.attach(d[c])};b.j(q,"load",b.N)}
/* Start ActivityMap Module */
function AppMeasurement_Module_ActivityMap(f){function g(a,d){var b,c,n;if(a&&d&&(b=e.c[d]||(e.c[d]=d.split(","))))for(n=0;n<b.length&&(c=b[n++]);)if(-1<a.indexOf(c))return null;p=1;return a}function q(a,d,b,c,e){var g,h;if(a.dataset&&(h=a.dataset[d]))g=h;else if(a.getAttribute)if(h=a.getAttribute("data-"+b))g=h;else if(h=a.getAttribute(b))g=h;if(!g&&f.useForcedLinkTracking&&e&&(g="",d=a.onclick?""+a.onclick:"")){b=d.indexOf(c);var l,k;if(0<=b){for(b+=10;b<d.length&&0<="= \t\r\n".indexOf(d.charAt(b));)b++;
if(b<d.length){h=b;for(l=k=0;h<d.length&&(";"!=d.charAt(h)||l);)l?d.charAt(h)!=l||k?k="\\"==d.charAt(h)?!k:0:l=0:(l=d.charAt(h),'"'!=l&&"'"!=l&&(l=0)),h++;if(d=d.substring(b,h))a.e=new Function("s","var e;try{s.w."+c+"="+d+"}catch(e){}"),a.e(f)}}}return g||e&&f.w[c]}function r(a,d,b){var c;return(c=e[d](a,b))&&(p?(p=0,c):g(k(c),e[d+"Exclusions"]))}function s(a,d,b){var c;if(a&&!(1===(c=a.nodeType)&&(c=a.nodeName)&&(c=c.toUpperCase())&&t[c])&&(1===a.nodeType&&(c=a.nodeValue)&&(d[d.length]=c),b.a||
b.t||b.s||!a.getAttribute||((c=a.getAttribute("alt"))?b.a=c:(c=a.getAttribute("title"))?b.t=c:"IMG"==(""+a.nodeName).toUpperCase()&&(c=a.getAttribute("src")||a.src)&&(b.s=c)),(c=a.childNodes)&&c.length))for(a=0;a<c.length;a++)s(c[a],d,b)}function k(a){if(null==a||void 0==a)return a;try{return a.replace(RegExp("^[\\s\\n\\f\\r\\t\t-\r \u00a0\u1680\u180e\u2000-\u200a\u2028\u2029\u205f\u3000\ufeff]+","mg"),"").replace(RegExp("[\\s\\n\\f\\r\\t\t-\r \u00a0\u1680\u180e\u2000-\u200a\u2028\u2029\u205f\u3000\ufeff]+$",
"mg"),"").replace(RegExp("[\\s\\n\\f\\r\\t\t-\r \u00a0\u1680\u180e\u2000-\u200a\u2028\u2029\u205f\u3000\ufeff]{1,}","mg")," ").substring(0,254)}catch(d){}}var e=this;e.s=f;var m=window;m.s_c_in||(m.s_c_il=[],m.s_c_in=0);e._il=m.s_c_il;e._in=m.s_c_in;e._il[e._in]=e;m.s_c_in++;e._c="s_m";e.c={};var p=0,t={SCRIPT:1,STYLE:1,LINK:1,CANVAS:1};e._g=function(){var a,d,b,c=f.contextData,e=f.linkObject;(a=f.pageName||f.pageURL)&&(d=r(e,"link",f.linkName))&&(b=r(e,"region"))&&(c["a.activitymap.page"]=a.substring(0,
255),c["a.activitymap.link"]=128<d.length?d.substring(0,128):d,c["a.activitymap.region"]=127<b.length?b.substring(0,127):b,c["a.activitymap.pageIDType"]=f.pageName?1:0)};e.link=function(a,d){var b;if(d)b=g(k(d),e.linkExclusions);else if((b=a)&&!(b=q(a,"sObjectId","s-object-id","s_objectID",1))){var c,f;(f=g(k(a.innerText||a.textContent),e.linkExclusions))||(s(a,c=[],b={a:void 0,t:void 0,s:void 0}),(f=g(k(c.join(""))))||(f=g(k(b.a?b.a:b.t?b.t:b.s?b.s:void 0)))||!(c=(c=a.tagName)&&c.toUpperCase?c.toUpperCase():
"")||("INPUT"==c||"SUBMIT"==c&&a.value?f=g(k(a.value)):"IMAGE"==c&&a.src&&(f=g(k(a.src)))));b=f}return b};e.region=function(a){for(var d,b=e.regionIDAttribute||"id";a&&(a=a.parentNode);){if(d=q(a,b,b,b))return d;if("BODY"==a.nodeName)return"BODY"}}}

/*********************** END MODULE SECTION ************************/

/*
 ============== DO NOT ALTER ANYTHING BELOW THIS LINE ! ===============

AppMeasurement for JavaScript version: 2.9.0
Copyright 1996-2016 Adobe, Inc. All Rights Reserved
More info available at http://www.adobe.com/marketing-cloud.html
*/
function AppMeasurement(r){var a=this;a.version="2.9.0";var k=window;k.s_c_in||(k.s_c_il=[],k.s_c_in=0);a._il=k.s_c_il;a._in=k.s_c_in;a._il[a._in]=a;k.s_c_in++;a._c="s_c";var p=k.AppMeasurement.Mb;p||(p=null);var n=k,m,s;try{for(m=n.parent,s=n.location;m&&m.location&&s&&""+m.location!=""+s&&n.location&&""+m.location!=""+n.location&&m.location.host==s.host;)n=m,m=n.parent}catch(u){}a.D=function(a){try{console.log(a)}catch(b){}};a.Ga=function(a){return""+parseInt(a)==""+a};a.replace=function(a,b,d){return!a||
0>a.indexOf(b)?a:a.split(b).join(d)};a.escape=function(c){var b,d;if(!c)return c;c=encodeURIComponent(c);for(b=0;7>b;b++)d="+~!*()'".substring(b,b+1),0<=c.indexOf(d)&&(c=a.replace(c,d,"%"+d.charCodeAt(0).toString(16).toUpperCase()));return c};a.unescape=function(c){if(!c)return c;c=0<=c.indexOf("+")?a.replace(c,"+"," "):c;try{return decodeURIComponent(c)}catch(b){}return unescape(c)};a.tb=function(){var c=k.location.hostname,b=a.fpCookieDomainPeriods,d;b||(b=a.cookieDomainPeriods);if(c&&!a.ya&&!/^[0-9.]+$/.test(c)&&
(b=b?parseInt(b):2,b=2<b?b:2,d=c.lastIndexOf("."),0<=d)){for(;0<=d&&1<b;)d=c.lastIndexOf(".",d-1),b--;a.ya=0<d?c.substring(d):c}return a.ya};a.c_r=a.cookieRead=function(c){c=a.escape(c);var b=" "+a.d.cookie,d=b.indexOf(" "+c+"="),f=0>d?d:b.indexOf(";",d);c=0>d?"":a.unescape(b.substring(d+2+c.length,0>f?b.length:f));return"[[B]]"!=c?c:""};a.c_w=a.cookieWrite=function(c,b,d){var f=a.tb(),e=a.cookieLifetime,g;b=""+b;e=e?(""+e).toUpperCase():"";d&&"SESSION"!=e&&"NONE"!=e&&((g=""!=b?parseInt(e?e:0):-60)?
(d=new Date,d.setTime(d.getTime()+1E3*g)):1==d&&(d=new Date,g=d.getYear(),d.setYear(g+5+(1900>g?1900:0))));return c&&"NONE"!=e?(a.d.cookie=a.escape(c)+"="+a.escape(""!=b?b:"[[B]]")+"; path=/;"+(d&&"SESSION"!=e?" expires="+d.toUTCString()+";":"")+(f?" domain="+f+";":""),a.cookieRead(c)==b):0};a.qb=function(){var c=a.Util.getIeVersion();"number"===typeof c&&10>c&&(a.unsupportedBrowser=!0,a.fb(a,function(){}))};a.fb=function(a,b){for(var d in a)a.hasOwnProperty(d)&&"function"===typeof a[d]&&(a[d]=b)};
a.L=[];a.ba=function(c,b,d){if(a.za)return 0;a.maxDelay||(a.maxDelay=250);var f=0,e=(new Date).getTime()+a.maxDelay,g=a.d.visibilityState,h=["webkitvisibilitychange","visibilitychange"];g||(g=a.d.webkitVisibilityState);if(g&&"prerender"==g){if(!a.ca)for(a.ca=1,d=0;d<h.length;d++)a.d.addEventListener(h[d],function(){var c=a.d.visibilityState;c||(c=a.d.webkitVisibilityState);"visible"==c&&(a.ca=0,a.delayReady())});f=1;e=0}else d||a.o("_d")&&(f=1);f&&(a.L.push({m:c,a:b,t:e}),a.ca||setTimeout(a.delayReady,
a.maxDelay));return f};a.delayReady=function(){var c=(new Date).getTime(),b=0,d;for(a.o("_d")?b=1:a.qa();0<a.L.length;){d=a.L.shift();if(b&&!d.t&&d.t>c){a.L.unshift(d);setTimeout(a.delayReady,parseInt(a.maxDelay/2));break}a.za=1;a[d.m].apply(a,d.a);a.za=0}};a.setAccount=a.sa=function(c){var b,d;if(!a.ba("setAccount",arguments))if(a.account=c,a.allAccounts)for(b=a.allAccounts.concat(c.split(",")),a.allAccounts=[],b.sort(),d=0;d<b.length;d++)0!=d&&b[d-1]==b[d]||a.allAccounts.push(b[d]);else a.allAccounts=
c.split(",")};a.foreachVar=function(c,b){var d,f,e,g,h="";e=f="";if(a.lightProfileID)d=a.P,(h=a.lightTrackVars)&&(h=","+h+","+a.ga.join(",")+",");else{d=a.g;if(a.pe||a.linkType)h=a.linkTrackVars,f=a.linkTrackEvents,a.pe&&(e=a.pe.substring(0,1).toUpperCase()+a.pe.substring(1),a[e]&&(h=a[e].Kb,f=a[e].Jb));h&&(h=","+h+","+a.G.join(",")+",");f&&h&&(h+=",events,")}b&&(b=","+b+",");for(f=0;f<d.length;f++)e=d[f],(g=a[e])&&(!h||0<=h.indexOf(","+e+","))&&(!b||0<=b.indexOf(","+e+","))&&c(e,g)};a.q=function(c,
b,d,f,e){var g="",h,l,k,q,m=0;"contextData"==c&&(c="c");if(b){for(h in b)if(!(Object.prototype[h]||e&&h.substring(0,e.length)!=e)&&b[h]&&(!d||0<=d.indexOf(","+(f?f+".":"")+h+","))){k=!1;if(m)for(l=0;l<m.length;l++)h.substring(0,m[l].length)==m[l]&&(k=!0);if(!k&&(""==g&&(g+="&"+c+"."),l=b[h],e&&(h=h.substring(e.length)),0<h.length))if(k=h.indexOf("."),0<k)l=h.substring(0,k),k=(e?e:"")+l+".",m||(m=[]),m.push(k),g+=a.q(l,b,d,f,k);else if("boolean"==typeof l&&(l=l?"true":"false"),l){if("retrieveLightData"==
f&&0>e.indexOf(".contextData."))switch(k=h.substring(0,4),q=h.substring(4),h){case "transactionID":h="xact";break;case "channel":h="ch";break;case "campaign":h="v0";break;default:a.Ga(q)&&("prop"==k?h="c"+q:"eVar"==k?h="v"+q:"list"==k?h="l"+q:"hier"==k&&(h="h"+q,l=l.substring(0,255)))}g+="&"+a.escape(h)+"="+a.escape(l)}}""!=g&&(g+="&."+c)}return g};a.usePostbacks=0;a.wb=function(){var c="",b,d,f,e,g,h,l,k,q="",m="",n=e="";if(a.lightProfileID)b=a.P,(q=a.lightTrackVars)&&(q=","+q+","+a.ga.join(",")+
",");else{b=a.g;if(a.pe||a.linkType)q=a.linkTrackVars,m=a.linkTrackEvents,a.pe&&(e=a.pe.substring(0,1).toUpperCase()+a.pe.substring(1),a[e]&&(q=a[e].Kb,m=a[e].Jb));q&&(q=","+q+","+a.G.join(",")+",");m&&(m=","+m+",",q&&(q+=",events,"));a.events2&&(n+=(""!=n?",":"")+a.events2)}if(a.visitor&&a.visitor.getCustomerIDs){e=p;if(g=a.visitor.getCustomerIDs())for(d in g)Object.prototype[d]||(f=g[d],"object"==typeof f&&(e||(e={}),f.id&&(e[d+".id"]=f.id),f.authState&&(e[d+".as"]=f.authState)));e&&(c+=a.q("cid",
e))}a.AudienceManagement&&a.AudienceManagement.isReady()&&(c+=a.q("d",a.AudienceManagement.getEventCallConfigParams()));for(d=0;d<b.length;d++){e=b[d];g=a[e];f=e.substring(0,4);h=e.substring(4);g||("events"==e&&n?(g=n,n=""):"marketingCloudOrgID"==e&&a.visitor&&(g=a.visitor.marketingCloudOrgID));if(g&&(!q||0<=q.indexOf(","+e+","))){switch(e){case "customerPerspective":e="cp";break;case "marketingCloudOrgID":e="mcorgid";break;case "supplementalDataID":e="sdid";break;case "timestamp":e="ts";break;case "dynamicVariablePrefix":e=
"D";break;case "visitorID":e="vid";break;case "marketingCloudVisitorID":e="mid";break;case "analyticsVisitorID":e="aid";break;case "audienceManagerLocationHint":e="aamlh";break;case "audienceManagerBlob":e="aamb";break;case "authState":e="as";break;case "pageURL":e="g";255<g.length&&(a.pageURLRest=g.substring(255),g=g.substring(0,255));break;case "pageURLRest":e="-g";break;case "referrer":e="r";break;case "vmk":case "visitorMigrationKey":e="vmt";break;case "visitorMigrationServer":e="vmf";a.ssl&&
a.visitorMigrationServerSecure&&(g="");break;case "visitorMigrationServerSecure":e="vmf";!a.ssl&&a.visitorMigrationServer&&(g="");break;case "charSet":e="ce";break;case "visitorNamespace":e="ns";break;case "cookieDomainPeriods":e="cdp";break;case "cookieLifetime":e="cl";break;case "variableProvider":e="vvp";break;case "currencyCode":e="cc";break;case "channel":e="ch";break;case "transactionID":e="xact";break;case "campaign":e="v0";break;case "latitude":e="lat";break;case "longitude":e="lon";break;
case "resolution":e="s";break;case "colorDepth":e="c";break;case "javascriptVersion":e="j";break;case "javaEnabled":e="v";break;case "cookiesEnabled":e="k";break;case "browserWidth":e="bw";break;case "browserHeight":e="bh";break;case "connectionType":e="ct";break;case "homepage":e="hp";break;case "events":n&&(g+=(""!=g?",":"")+n);if(m)for(h=g.split(","),g="",f=0;f<h.length;f++)l=h[f],k=l.indexOf("="),0<=k&&(l=l.substring(0,k)),k=l.indexOf(":"),0<=k&&(l=l.substring(0,k)),0<=m.indexOf(","+l+",")&&(g+=
(g?",":"")+h[f]);break;case "events2":g="";break;case "contextData":c+=a.q("c",a[e],q,e);g="";break;case "lightProfileID":e="mtp";break;case "lightStoreForSeconds":e="mtss";a.lightProfileID||(g="");break;case "lightIncrementBy":e="mti";a.lightProfileID||(g="");break;case "retrieveLightProfiles":e="mtsr";break;case "deleteLightProfiles":e="mtsd";break;case "retrieveLightData":a.retrieveLightProfiles&&(c+=a.q("mts",a[e],q,e));g="";break;default:a.Ga(h)&&("prop"==f?e="c"+h:"eVar"==f?e="v"+h:"list"==
f?e="l"+h:"hier"==f&&(e="h"+h,g=g.substring(0,255)))}g&&(c+="&"+e+"="+("pev"!=e.substring(0,3)?a.escape(g):g))}"pev3"==e&&a.e&&(c+=a.e)}a.fa&&(c+="&lrt="+a.fa,a.fa=null);return c};a.C=function(a){var b=a.tagName;if("undefined"!=""+a.Pb||"undefined"!=""+a.Fb&&"HTML"!=(""+a.Fb).toUpperCase())return"";b=b&&b.toUpperCase?b.toUpperCase():"";"SHAPE"==b&&(b="");b&&(("INPUT"==b||"BUTTON"==b)&&a.type&&a.type.toUpperCase?b=a.type.toUpperCase():!b&&a.href&&(b="A"));return b};a.Ca=function(a){var b=k.location,
d=a.href?a.href:"",f,e,g;f=d.indexOf(":");e=d.indexOf("?");g=d.indexOf("/");d&&(0>f||0<=e&&f>e||0<=g&&f>g)&&(e=a.protocol&&1<a.protocol.length?a.protocol:b.protocol?b.protocol:"",f=b.pathname.lastIndexOf("/"),d=(e?e+"//":"")+(a.host?a.host:b.host?b.host:"")+("/"!=d.substring(0,1)?b.pathname.substring(0,0>f?0:f)+"/":"")+d);return d};a.M=function(c){var b=a.C(c),d,f,e="",g=0;return b&&(d=c.protocol,f=c.onclick,!c.href||"A"!=b&&"AREA"!=b||f&&d&&!(0>d.toLowerCase().indexOf("javascript"))?f?(e=a.replace(a.replace(a.replace(a.replace(""+
f,"\r",""),"\n",""),"\t","")," ",""),g=2):"INPUT"==b||"SUBMIT"==b?(c.value?e=c.value:c.innerText?e=c.innerText:c.textContent&&(e=c.textContent),g=3):"IMAGE"==b&&c.src&&(e=c.src):e=a.Ca(c),e)?{id:e.substring(0,100),type:g}:0};a.Nb=function(c){for(var b=a.C(c),d=a.M(c);c&&!d&&"BODY"!=b;)if(c=c.parentElement?c.parentElement:c.parentNode)b=a.C(c),d=a.M(c);d&&"BODY"!=b||(c=0);c&&(b=c.onclick?""+c.onclick:"",0<=b.indexOf(".tl(")||0<=b.indexOf(".trackLink("))&&(c=0);return c};a.Eb=function(){var c,b,d=a.linkObject,
f=a.linkType,e=a.linkURL,g,h;a.ha=1;d||(a.ha=0,d=a.clickObject);if(d){c=a.C(d);for(b=a.M(d);d&&!b&&"BODY"!=c;)if(d=d.parentElement?d.parentElement:d.parentNode)c=a.C(d),b=a.M(d);b&&"BODY"!=c||(d=0);if(d&&!a.linkObject){var l=d.onclick?""+d.onclick:"";if(0<=l.indexOf(".tl(")||0<=l.indexOf(".trackLink("))d=0}}else a.ha=1;!e&&d&&(e=a.Ca(d));e&&!a.linkLeaveQueryString&&(g=e.indexOf("?"),0<=g&&(e=e.substring(0,g)));if(!f&&e){var m=0,q=0,n;if(a.trackDownloadLinks&&a.linkDownloadFileTypes)for(l=e.toLowerCase(),
g=l.indexOf("?"),h=l.indexOf("#"),0<=g?0<=h&&h<g&&(g=h):g=h,0<=g&&(l=l.substring(0,g)),g=a.linkDownloadFileTypes.toLowerCase().split(","),h=0;h<g.length;h++)(n=g[h])&&l.substring(l.length-(n.length+1))=="."+n&&(f="d");if(a.trackExternalLinks&&!f&&(l=e.toLowerCase(),a.Fa(l)&&(a.linkInternalFilters||(a.linkInternalFilters=k.location.hostname),g=0,a.linkExternalFilters?(g=a.linkExternalFilters.toLowerCase().split(","),m=1):a.linkInternalFilters&&(g=a.linkInternalFilters.toLowerCase().split(",")),g))){for(h=
0;h<g.length;h++)n=g[h],0<=l.indexOf(n)&&(q=1);q?m&&(f="e"):m||(f="e")}}a.linkObject=d;a.linkURL=e;a.linkType=f;if(a.trackClickMap||a.trackInlineStats)a.e="",d&&(f=a.pageName,e=1,d=d.sourceIndex,f||(f=a.pageURL,e=0),k.s_objectID&&(b.id=k.s_objectID,d=b.type=1),f&&b&&b.id&&c&&(a.e="&pid="+a.escape(f.substring(0,255))+(e?"&pidt="+e:"")+"&oid="+a.escape(b.id.substring(0,100))+(b.type?"&oidt="+b.type:"")+"&ot="+c+(d?"&oi="+d:"")))};a.xb=function(){var c=a.ha,b=a.linkType,d=a.linkURL,f=a.linkName;b&&(d||
f)&&(b=b.toLowerCase(),"d"!=b&&"e"!=b&&(b="o"),a.pe="lnk_"+b,a.pev1=d?a.escape(d):"",a.pev2=f?a.escape(f):"",c=1);a.abort&&(c=0);if(a.trackClickMap||a.trackInlineStats||a.Ab()){var b={},d=0,e=a.cookieRead("s_sq"),g=e?e.split("&"):0,h,l,k,e=0;if(g)for(h=0;h<g.length;h++)l=g[h].split("="),f=a.unescape(l[0]).split(","),l=a.unescape(l[1]),b[l]=f;f=a.account.split(",");h={};for(k in a.contextData)k&&!Object.prototype[k]&&"a.activitymap."==k.substring(0,14)&&(h[k]=a.contextData[k],a.contextData[k]="");
a.e=a.q("c",h)+(a.e?a.e:"");if(c||a.e){c&&!a.e&&(e=1);for(l in b)if(!Object.prototype[l])for(k=0;k<f.length;k++)for(e&&(g=b[l].join(","),g==a.account&&(a.e+=("&"!=l.charAt(0)?"&":"")+l,b[l]=[],d=1)),h=0;h<b[l].length;h++)g=b[l][h],g==f[k]&&(e&&(a.e+="&u="+a.escape(g)+("&"!=l.charAt(0)?"&":"")+l+"&u=0"),b[l].splice(h,1),d=1);c||(d=1);if(d){e="";h=2;!c&&a.e&&(e=a.escape(f.join(","))+"="+a.escape(a.e),h=1);for(l in b)!Object.prototype[l]&&0<h&&0<b[l].length&&(e+=(e?"&":"")+a.escape(b[l].join(","))+"="+
a.escape(l),h--);a.cookieWrite("s_sq",e)}}}return c};a.yb=function(){if(!a.Ib){var c=new Date,b=n.location,d,f,e=f=d="",g="",h="",l="1.2",k=a.cookieWrite("s_cc","true",0)?"Y":"N",m="",p="";if(c.setUTCDate&&(l="1.3",(0).toPrecision&&(l="1.5",c=[],c.forEach))){l="1.6";f=0;d={};try{f=new Iterator(d),f.next&&(l="1.7",c.reduce&&(l="1.8",l.trim&&(l="1.8.1",Date.parse&&(l="1.8.2",Object.create&&(l="1.8.5")))))}catch(r){}}d=screen.width+"x"+screen.height;e=navigator.javaEnabled()?"Y":"N";f=screen.pixelDepth?
screen.pixelDepth:screen.colorDepth;g=a.w.innerWidth?a.w.innerWidth:a.d.documentElement.offsetWidth;h=a.w.innerHeight?a.w.innerHeight:a.d.documentElement.offsetHeight;try{a.b.addBehavior("#default#homePage"),m=a.b.Ob(b)?"Y":"N"}catch(s){}try{a.b.addBehavior("#default#clientCaps"),p=a.b.connectionType}catch(t){}a.resolution=d;a.colorDepth=f;a.javascriptVersion=l;a.javaEnabled=e;a.cookiesEnabled=k;a.browserWidth=g;a.browserHeight=h;a.connectionType=p;a.homepage=m;a.Ib=1}};a.Q={};a.loadModule=function(c,
b){var d=a.Q[c];if(!d){d=k["AppMeasurement_Module_"+c]?new k["AppMeasurement_Module_"+c](a):{};a.Q[c]=a[c]=d;d.Za=function(){return d.eb};d.gb=function(b){if(d.eb=b)a[c+"_onLoad"]=b,a.ba(c+"_onLoad",[a,d],1)||b(a,d)};try{Object.defineProperty?Object.defineProperty(d,"onLoad",{get:d.Za,set:d.gb}):d._olc=1}catch(f){d._olc=1}}b&&(a[c+"_onLoad"]=b,a.ba(c+"_onLoad",[a,d],1)||b(a,d))};a.o=function(c){var b,d;for(b in a.Q)if(!Object.prototype[b]&&(d=a.Q[b])&&(d._olc&&d.onLoad&&(d._olc=0,d.onLoad(a,d)),d[c]&&
d[c]()))return 1;return 0};a.Ab=function(){return a.ActivityMap&&a.ActivityMap._c?!0:!1};a.Bb=function(){var c=Math.floor(1E13*Math.random()),b=a.visitorSampling,d=a.visitorSamplingGroup,d="s_vsn_"+(a.visitorNamespace?a.visitorNamespace:a.account)+(d?"_"+d:""),f=a.cookieRead(d);if(b){b*=100;f&&(f=parseInt(f));if(!f){if(!a.cookieWrite(d,c))return 0;f=c}if(f%1E4>b)return 0}return 1};a.R=function(c,b){var d,f,e,g,h,l;for(d=0;2>d;d++)for(f=0<d?a.ua:a.g,e=0;e<f.length;e++)if(g=f[e],(h=c[g])||c["!"+g]){if(!b&&
("contextData"==g||"retrieveLightData"==g)&&a[g])for(l in a[g])h[l]||(h[l]=a[g][l]);a[g]=h}};a.Qa=function(c,b){var d,f,e,g;for(d=0;2>d;d++)for(f=0<d?a.ua:a.g,e=0;e<f.length;e++)g=f[e],c[g]=a[g],b||c[g]||(c["!"+g]=1)};a.sb=function(a){var b,d,f,e,g,h=0,l,k="",m="";if(a&&255<a.length&&(b=""+a,d=b.indexOf("?"),0<d&&(l=b.substring(d+1),b=b.substring(0,d),e=b.toLowerCase(),f=0,"http://"==e.substring(0,7)?f+=7:"https://"==e.substring(0,8)&&(f+=8),d=e.indexOf("/",f),0<d&&(e=e.substring(f,d),g=b.substring(d),
b=b.substring(0,d),0<=e.indexOf("google")?h=",q,ie,start,search_key,word,kw,cd,":0<=e.indexOf("yahoo.co")&&(h=",p,ei,"),h&&l)))){if((a=l.split("&"))&&1<a.length){for(f=0;f<a.length;f++)e=a[f],d=e.indexOf("="),0<d&&0<=h.indexOf(","+e.substring(0,d)+",")?k+=(k?"&":"")+e:m+=(m?"&":"")+e;k&&m?l=k+"&"+m:m=""}d=253-(l.length-m.length)-b.length;a=b+(0<d?g.substring(0,d):"")+"?"+l}return a};a.Ta=function(c){var b=a.d.visibilityState,d=["webkitvisibilitychange","visibilitychange"];b||(b=a.d.webkitVisibilityState);
if(b&&"prerender"==b){if(c)for(b=0;b<d.length;b++)a.d.addEventListener(d[b],function(){var b=a.d.visibilityState;b||(b=a.d.webkitVisibilityState);"visible"==b&&c()});return!1}return!0};a.X=!1;a.J=!1;a.ib=function(){a.J=!0;a.H()};a.Y=!1;a.S=!1;a.jb=function(c){a.marketingCloudVisitorID=c.MCMID;a.visitorOptedOut=c.MCOPTOUT;a.analyticsVisitorID=c.MCAID;a.audienceManagerLocationHint=c.MCAAMLH;a.audienceManagerBlob=c.MCAAMB;a.S=!0;a.H()};a.Sa=function(c){a.maxDelay||(a.maxDelay=250);return a.o("_d")?(c&&
setTimeout(function(){c()},a.maxDelay),!1):!0};a.W=!1;a.I=!1;a.qa=function(){a.I=!0;a.H()};a.isReadyToTrack=function(){var c=!0,b=a.visitor;a.X||a.J||(a.Ta(a.ib)?a.J=!0:a.X=!0);if(a.X&&!a.J)return!1;b&&b.isAllowed()&&(a.Y||a.marketingCloudVisitorID||!b.getVisitorValues||(a.Y=!0,a.marketingCloudVisitorID?a.S=!0:b.getVisitorValues(a.jb)),c=!a.Y||a.S||a.marketingCloudVisitorID?!0:!1);a.W||a.I||(a.Sa(a.qa)?a.I=!0:a.W=!0);a.W&&!a.I&&(c=!1);return c};a.l=p;a.r=0;a.callbackWhenReadyToTrack=function(c,b,
d){var f;f={};f.nb=c;f.mb=b;f.kb=d;a.l==p&&(a.l=[]);a.l.push(f);0==a.r&&(a.r=setInterval(a.H,100))};a.H=function(){var c;if(a.isReadyToTrack()&&(a.hb(),a.l!=p))for(;0<a.l.length;)c=a.l.shift(),c.mb.apply(c.nb,c.kb)};a.hb=function(){a.r&&(clearInterval(a.r),a.r=0)};a.ab=function(c){var b,d,f=p,e=p;if(!a.isReadyToTrack()){b=[];if(c!=p)for(d in f={},c)f[d]=c[d];e={};a.Qa(e,!0);b.push(f);b.push(e);a.callbackWhenReadyToTrack(a,a.track,b);return!0}return!1};a.ub=function(){var c=a.cookieRead("s_fid"),b=
"",d="",f;f=8;var e=4;if(!c||0>c.indexOf("-")){for(c=0;16>c;c++)f=Math.floor(Math.random()*f),b+="0123456789ABCDEF".substring(f,f+1),f=Math.floor(Math.random()*e),d+="0123456789ABCDEF".substring(f,f+1),f=e=16;c=b+"-"+d}a.cookieWrite("s_fid",c,1)||(c=0);return c};a.t=a.track=function(c,b){var d,f=new Date,e="s"+Math.floor(f.getTime()/108E5)%10+Math.floor(1E13*Math.random()),g=f.getYear(),g="t="+a.escape(f.getDate()+"/"+f.getMonth()+"/"+(1900>g?g+1900:g)+" "+f.getHours()+":"+f.getMinutes()+":"+f.getSeconds()+
" "+f.getDay()+" "+f.getTimezoneOffset());a.visitor&&a.visitor.getAuthState&&(a.authState=a.visitor.getAuthState());a.o("_s");a.ab(c)||(b&&a.R(b),c&&(d={},a.Qa(d,0),a.R(c)),a.Bb()&&!a.visitorOptedOut&&(a.analyticsVisitorID||a.marketingCloudVisitorID||(a.fid=a.ub()),a.Eb(),a.usePlugins&&a.doPlugins&&a.doPlugins(a),a.account&&(a.abort||(a.trackOffline&&!a.timestamp&&(a.timestamp=Math.floor(f.getTime()/1E3)),f=k.location,a.pageURL||(a.pageURL=f.href?f.href:f),a.referrer||a.Ra||(f=a.Util.getQueryParam("adobe_mc_ref",
null,null,!0),a.referrer=f||void 0===f?void 0===f?"":f:n.document.referrer),a.Ra=1,a.referrer=a.sb(a.referrer),a.o("_g")),a.xb()&&!a.abort&&(a.visitor&&!a.supplementalDataID&&a.visitor.getSupplementalDataID&&(a.supplementalDataID=a.visitor.getSupplementalDataID("AppMeasurement:"+a._in,a.expectSupplementalData?!1:!0)),a.yb(),g+=a.wb(),a.cb(e,g),a.o("_t"),a.referrer=""))),c&&a.R(d,1));a.abort=a.supplementalDataID=a.timestamp=a.pageURLRest=a.linkObject=a.clickObject=a.linkURL=a.linkName=a.linkType=k.s_objectID=
a.pe=a.pev1=a.pev2=a.pev3=a.e=a.lightProfileID=0};a.ta=[];a.registerPreTrackCallback=function(c){for(var b=[],d=1;d<arguments.length;d++)b.push(arguments[d]);"function"==typeof c?a.ta.push([c,b]):a.debugTracking&&a.D("DEBUG: Non function type passed to registerPreTrackCallback")};a.Wa=function(c){a.oa(a.ta,c)};a.ra=[];a.registerPostTrackCallback=function(c){for(var b=[],d=1;d<arguments.length;d++)b.push(arguments[d]);"function"==typeof c?a.ra.push([c,b]):a.debugTracking&&a.D("DEBUG: Non function type passed to registerPostTrackCallback")};
a.Va=function(c){a.oa(a.ra,c)};a.oa=function(c,b){if("object"==typeof c)for(var d=0;d<c.length;d++){var f=c[d][0],e=c[d][1].slice();e.unshift(b);if("function"==typeof f)try{f.apply(null,e)}catch(g){a.debugTracking&&a.D(g.message)}}};a.tl=a.trackLink=function(c,b,d,f,e){a.linkObject=c;a.linkType=b;a.linkName=d;e&&(a.k=c,a.v=e);return a.track(f)};a.trackLight=function(c,b,d,f){a.lightProfileID=c;a.lightStoreForSeconds=b;a.lightIncrementBy=d;return a.track(f)};a.clearVars=function(){var c,b;for(c=0;c<
a.g.length;c++)if(b=a.g[c],"prop"==b.substring(0,4)||"eVar"==b.substring(0,4)||"hier"==b.substring(0,4)||"list"==b.substring(0,4)||"channel"==b||"events"==b||"eventList"==b||"products"==b||"productList"==b||"purchaseID"==b||"transactionID"==b||"state"==b||"zip"==b||"campaign"==b)a[b]=void 0};a.tagContainerMarker="";a.cb=function(c,b){var d=a.Xa()+"/"+c+"?AQB=1&ndh=1&pf=1&"+(a.pa()?"callback=s_c_il["+a._in+"].doPostbacks&et=1&":"")+b+"&AQE=1";a.Wa(d);a.Ua(d);a.T()};a.Xa=function(){var c=a.Ya();return"http"+
(a.ssl?"s":"")+"://"+c+"/b/ss/"+a.account+"/"+(a.mobile?"5.":"")+(a.pa()?"10":"1")+"/JS-"+a.version+(a.Hb?"T":"")+(a.tagContainerMarker?"-"+a.tagContainerMarker:"")};a.pa=function(){return a.AudienceManagement&&a.AudienceManagement.isReady()||0!=a.usePostbacks};a.Ya=function(){var c=a.dc,b=a.trackingServer;b?a.trackingServerSecure&&a.ssl&&(b=a.trackingServerSecure):(c=c?(""+c).toLowerCase():"d1","d1"==c?c="112":"d2"==c&&(c="122"),b=a.$a()+"."+c+".2o7.net");return b};a.$a=function(){var c=a.visitorNamespace;
c||(c=a.account.split(",")[0],c=c.replace(/[^0-9a-z]/gi,""));return c};a.Pa=/{(%?)(.*?)(%?)}/;a.Lb=RegExp(a.Pa.source,"g");a.rb=function(c){if("object"==typeof c.dests)for(var b=0;b<c.dests.length;++b){var d=c.dests[b];if("string"==typeof d.c&&"aa."==d.id.substr(0,3))for(var f=d.c.match(a.Lb),e=0;e<f.length;++e){var g=f[e],h=g.match(a.Pa),k="";"%"==h[1]&&"timezone_offset"==h[2]?k=(new Date).getTimezoneOffset():"%"==h[1]&&"timestampz"==h[2]&&(k=a.vb());d.c=d.c.replace(g,a.escape(k))}}};a.vb=function(){var c=
new Date,b=new Date(6E4*Math.abs(c.getTimezoneOffset()));return a.j(4,c.getFullYear())+"-"+a.j(2,c.getMonth()+1)+"-"+a.j(2,c.getDate())+"T"+a.j(2,c.getHours())+":"+a.j(2,c.getMinutes())+":"+a.j(2,c.getSeconds())+(0<c.getTimezoneOffset()?"-":"+")+a.j(2,b.getUTCHours())+":"+a.j(2,b.getUTCMinutes())};a.j=function(a,b){return(Array(a+1).join(0)+b).slice(-a)};a.la={};a.doPostbacks=function(c){if("object"==typeof c)if(a.rb(c),"object"==typeof a.AudienceManagement&&"function"==typeof a.AudienceManagement.isReady&&
a.AudienceManagement.isReady()&&"function"==typeof a.AudienceManagement.passData)a.AudienceManagement.passData(c);else if("object"==typeof c&&"object"==typeof c.dests)for(var b=0;b<c.dests.length;++b){var d=c.dests[b];"object"==typeof d&&"string"==typeof d.c&&"string"==typeof d.id&&"aa."==d.id.substr(0,3)&&(a.la[d.id]=new Image,a.la[d.id].alt="",a.la[d.id].src=d.c)}};a.Ua=function(c){a.i||a.zb();a.i.push(c);a.ea=a.B();a.Na()};a.zb=function(){a.i=a.Cb();a.i||(a.i=[])};a.Cb=function(){var c,b;if(a.ka()){try{(b=
k.localStorage.getItem(a.ia()))&&(c=k.JSON.parse(b))}catch(d){}return c}};a.ka=function(){var c=!0;a.trackOffline&&a.offlineFilename&&k.localStorage&&k.JSON||(c=!1);return c};a.Da=function(){var c=0;a.i&&(c=a.i.length);a.p&&c++;return c};a.T=function(){if(a.p&&(a.A&&a.A.complete&&a.A.F&&a.A.na(),a.p))return;a.Ea=p;if(a.ja)a.ea>a.O&&a.La(a.i),a.ma(500);else{var c=a.lb();if(0<c)a.ma(c);else if(c=a.Aa())a.p=1,a.Db(c),a.Gb(c)}};a.ma=function(c){a.Ea||(c||(c=0),a.Ea=setTimeout(a.T,c))};a.lb=function(){var c;
if(!a.trackOffline||0>=a.offlineThrottleDelay)return 0;c=a.B()-a.Ja;return a.offlineThrottleDelay<c?0:a.offlineThrottleDelay-c};a.Aa=function(){if(0<a.i.length)return a.i.shift()};a.Db=function(c){if(a.debugTracking){var b="AppMeasurement Debug: "+c;c=c.split("&");var d;for(d=0;d<c.length;d++)b+="\n\t"+a.unescape(c[d]);a.D(b)}};a.bb=function(){return a.marketingCloudVisitorID||a.analyticsVisitorID};a.V=!1;var t;try{t=JSON.parse('{"x":"y"}')}catch(w){t=null}t&&"y"==t.x?(a.V=!0,a.U=function(a){return JSON.parse(a)}):
k.$&&k.$.parseJSON?(a.U=function(a){return k.$.parseJSON(a)},a.V=!0):a.U=function(){return null};a.Gb=function(c){var b,d,f;a.bb()&&2047<c.length&&("undefined"!=typeof XMLHttpRequest&&(b=new XMLHttpRequest,"withCredentials"in b?d=1:b=0),b||"undefined"==typeof XDomainRequest||(b=new XDomainRequest,d=2),b&&(a.AudienceManagement&&a.AudienceManagement.isReady()||0!=a.usePostbacks)&&(a.V?b.va=!0:b=0));!b&&a.Oa&&(c=c.substring(0,2047));!b&&a.d.createElement&&(0!=a.usePostbacks||a.AudienceManagement&&a.AudienceManagement.isReady())&&
(b=a.d.createElement("SCRIPT"))&&"async"in b&&((f=(f=a.d.getElementsByTagName("HEAD"))&&f[0]?f[0]:a.d.body)?(b.type="text/javascript",b.setAttribute("async","async"),d=3):b=0);b||(b=new Image,b.alt="",b.abort||"undefined"===typeof k.InstallTrigger||(b.abort=function(){b.src=p}));b.Ka=Date.now();b.xa=function(){try{b.F&&(clearTimeout(b.F),b.F=0)}catch(a){}};b.onload=b.na=function(){b.Ka&&(a.fa=Date.now()-b.Ka);a.Va(c);b.xa();a.pb();a.Z();a.p=0;a.T();if(b.va){b.va=!1;try{a.doPostbacks(a.U(b.responseText))}catch(d){}}};
b.onabort=b.onerror=b.Ba=function(){b.xa();(a.trackOffline||a.ja)&&a.p&&a.i.unshift(a.ob);a.p=0;a.ea>a.O&&a.La(a.i);a.Z();a.ma(500)};b.onreadystatechange=function(){4==b.readyState&&(200==b.status?b.na():b.Ba())};a.Ja=a.B();if(1==d||2==d){var e=c.indexOf("?");f=c.substring(0,e);e=c.substring(e+1);e=e.replace(/&callback=[a-zA-Z0-9_.\[\]]+/,"");1==d?(b.open("POST",f,!0),b.send(e)):2==d&&(b.open("POST",f),b.send(e))}else if(b.src=c,3==d){if(a.Ha)try{f.removeChild(a.Ha)}catch(g){}f.firstChild?f.insertBefore(b,
f.firstChild):f.appendChild(b);a.Ha=a.A}b.F=setTimeout(function(){b.F&&(b.complete?b.na():(a.trackOffline&&b.abort&&b.abort(),b.Ba()))},5E3);a.ob=c;a.A=k["s_i_"+a.replace(a.account,",","_")]=b;if(a.useForcedLinkTracking&&a.K||a.v)a.forcedLinkTrackingTimeout||(a.forcedLinkTrackingTimeout=250),a.aa=setTimeout(a.Z,a.forcedLinkTrackingTimeout)};a.pb=function(){if(a.ka()&&!(a.Ia>a.O))try{k.localStorage.removeItem(a.ia()),a.Ia=a.B()}catch(c){}};a.La=function(c){if(a.ka()){a.Na();try{k.localStorage.setItem(a.ia(),
k.JSON.stringify(c)),a.O=a.B()}catch(b){}}};a.Na=function(){if(a.trackOffline){if(!a.offlineLimit||0>=a.offlineLimit)a.offlineLimit=10;for(;a.i.length>a.offlineLimit;)a.Aa()}};a.forceOffline=function(){a.ja=!0};a.forceOnline=function(){a.ja=!1};a.ia=function(){return a.offlineFilename+"-"+a.visitorNamespace+a.account};a.B=function(){return(new Date).getTime()};a.Fa=function(a){a=a.toLowerCase();return 0!=a.indexOf("#")&&0!=a.indexOf("about:")&&0!=a.indexOf("opera:")&&0!=a.indexOf("javascript:")?!0:
!1};a.setTagContainer=function(c){var b,d,f;a.Hb=c;for(b=0;b<a._il.length;b++)if((d=a._il[b])&&"s_l"==d._c&&d.tagContainerName==c){a.R(d);if(d.lmq)for(b=0;b<d.lmq.length;b++)f=d.lmq[b],a.loadModule(f.n);if(d.ml)for(f in d.ml)if(a[f])for(b in c=a[f],f=d.ml[f],f)!Object.prototype[b]&&("function"!=typeof f[b]||0>(""+f[b]).indexOf("s_c_il"))&&(c[b]=f[b]);if(d.mmq)for(b=0;b<d.mmq.length;b++)f=d.mmq[b],a[f.m]&&(c=a[f.m],c[f.f]&&"function"==typeof c[f.f]&&(f.a?c[f.f].apply(c,f.a):c[f.f].apply(c)));if(d.tq)for(b=
0;b<d.tq.length;b++)a.track(d.tq[b]);d.s=a;break}};a.Util={urlEncode:a.escape,urlDecode:a.unescape,cookieRead:a.cookieRead,cookieWrite:a.cookieWrite,getQueryParam:function(c,b,d,f){var e,g="";b||(b=a.pageURL?a.pageURL:k.location);d=d?d:"&";if(!c||!b)return g;b=""+b;e=b.indexOf("?");if(0>e)return g;b=d+b.substring(e+1)+d;if(!f||!(0<=b.indexOf(d+c+d)||0<=b.indexOf(d+c+"="+d))){e=b.indexOf("#");0<=e&&(b=b.substr(0,e)+d);e=b.indexOf(d+c+"=");if(0>e)return g;b=b.substring(e+d.length+c.length+1);e=b.indexOf(d);
0<=e&&(b=b.substring(0,e));0<b.length&&(g=a.unescape(b));return g}},getIeVersion:function(){if(document.documentMode)return document.documentMode;for(var a=7;4<a;a--){var b=document.createElement("div");b.innerHTML="\x3c!--[if IE "+a+"]><span></span><![endif]--\x3e";if(b.getElementsByTagName("span").length)return a}return null}};a.G="supplementalDataID timestamp dynamicVariablePrefix visitorID marketingCloudVisitorID analyticsVisitorID audienceManagerLocationHint authState fid vmk visitorMigrationKey visitorMigrationServer visitorMigrationServerSecure charSet visitorNamespace cookieDomainPeriods fpCookieDomainPeriods cookieLifetime pageName pageURL customerPerspective referrer contextData currencyCode lightProfileID lightStoreForSeconds lightIncrementBy retrieveLightProfiles deleteLightProfiles retrieveLightData".split(" ");
a.g=a.G.concat("purchaseID variableProvider channel server pageType transactionID campaign state zip events events2 products audienceManagerBlob tnt".split(" "));a.ga="timestamp charSet visitorNamespace cookieDomainPeriods cookieLifetime contextData lightProfileID lightStoreForSeconds lightIncrementBy".split(" ");a.P=a.ga.slice(0);a.ua="account allAccounts debugTracking visitor visitorOptedOut trackOffline offlineLimit offlineThrottleDelay offlineFilename usePlugins doPlugins configURL visitorSampling visitorSamplingGroup linkObject clickObject linkURL linkName linkType trackDownloadLinks trackExternalLinks trackClickMap trackInlineStats linkLeaveQueryString linkTrackVars linkTrackEvents linkDownloadFileTypes linkExternalFilters linkInternalFilters useForcedLinkTracking forcedLinkTrackingTimeout trackingServer trackingServerSecure ssl abort mobile dc lightTrackVars maxDelay expectSupplementalData usePostbacks registerPreTrackCallback registerPostTrackCallback AudienceManagement".split(" ");
for(m=0;250>=m;m++)76>m&&(a.g.push("prop"+m),a.P.push("prop"+m)),a.g.push("eVar"+m),a.P.push("eVar"+m),6>m&&a.g.push("hier"+m),4>m&&a.g.push("list"+m);m="pe pev1 pev2 pev3 latitude longitude resolution colorDepth javascriptVersion javaEnabled cookiesEnabled browserWidth browserHeight connectionType homepage pageURLRest marketingCloudOrgID".split(" ");a.g=a.g.concat(m);a.G=a.G.concat(m);a.ssl=0<=k.location.protocol.toLowerCase().indexOf("https");a.charSet="UTF-8";a.contextData={};a.offlineThrottleDelay=
0;a.offlineFilename="AppMeasurement.offline";a.Ja=0;a.ea=0;a.O=0;a.Ia=0;a.linkDownloadFileTypes="exe,zip,wav,mp3,mov,mpg,avi,wmv,pdf,doc,docx,xls,xlsx,ppt,pptx";a.w=k;a.d=k.document;try{if(a.Oa=!1,navigator){var v=navigator.userAgent;if("Microsoft Internet Explorer"==navigator.appName||0<=v.indexOf("MSIE ")||0<=v.indexOf("Trident/")&&0<=v.indexOf("Windows NT 6"))a.Oa=!0}}catch(x){}a.Z=function(){a.aa&&(k.clearTimeout(a.aa),a.aa=p);a.k&&a.K&&a.k.dispatchEvent(a.K);a.v&&("function"==typeof a.v?a.v():
a.k&&a.k.href&&(a.d.location=a.k.href));a.k=a.K=a.v=0};a.Ma=function(){a.b=a.d.body;a.b?(a.u=function(c){var b,d,f,e,g;if(!(a.d&&a.d.getElementById("cppXYctnr")||c&&c["s_fe_"+a._in])){if(a.wa)if(a.useForcedLinkTracking)a.b.removeEventListener("click",a.u,!1);else{a.b.removeEventListener("click",a.u,!0);a.wa=a.useForcedLinkTracking=0;return}else a.useForcedLinkTracking=0;a.clickObject=c.srcElement?c.srcElement:c.target;try{if(!a.clickObject||a.N&&a.N==a.clickObject||!(a.clickObject.tagName||a.clickObject.parentElement||
a.clickObject.parentNode))a.clickObject=0;else{var h=a.N=a.clickObject;a.da&&(clearTimeout(a.da),a.da=0);a.da=setTimeout(function(){a.N==h&&(a.N=0)},1E4);f=a.Da();a.track();if(f<a.Da()&&a.useForcedLinkTracking&&c.target){for(e=c.target;e&&e!=a.b&&"A"!=e.tagName.toUpperCase()&&"AREA"!=e.tagName.toUpperCase();)e=e.parentNode;if(e&&(g=e.href,a.Fa(g)||(g=0),d=e.target,c.target.dispatchEvent&&g&&(!d||"_self"==d||"_top"==d||"_parent"==d||k.name&&d==k.name))){try{b=a.d.createEvent("MouseEvents")}catch(l){b=
new k.MouseEvent}if(b){try{b.initMouseEvent("click",c.bubbles,c.cancelable,c.view,c.detail,c.screenX,c.screenY,c.clientX,c.clientY,c.ctrlKey,c.altKey,c.shiftKey,c.metaKey,c.button,c.relatedTarget)}catch(m){b=0}b&&(b["s_fe_"+a._in]=b.s_fe=1,c.stopPropagation(),c.stopImmediatePropagation&&c.stopImmediatePropagation(),c.preventDefault(),a.k=c.target,a.K=b)}}}}}catch(n){a.clickObject=0}}},a.b&&a.b.attachEvent?a.b.attachEvent("onclick",a.u):a.b&&a.b.addEventListener&&(navigator&&(0<=navigator.userAgent.indexOf("WebKit")&&
a.d.createEvent||0<=navigator.userAgent.indexOf("Firefox/2")&&k.MouseEvent)&&(a.wa=1,a.useForcedLinkTracking=1,a.b.addEventListener("click",a.u,!0)),a.b.addEventListener("click",a.u,!1))):setTimeout(a.Ma,30)};a.qb();a.Qb||(r?a.setAccount(r):a.D("Error, missing Report Suite ID in AppMeasurement initialization"),a.Ma(),a.loadModule("ActivityMap"))}
function s_gi(r){var a,k=window.s_c_il,p,n,m=r.split(","),s,u,t=0;if(k)for(p=0;!t&&p<k.length;){a=k[p];if("s_c"==a._c&&(a.account||a.oun))if(a.account&&a.account==r)t=1;else for(n=a.account?a.account:a.oun,n=a.allAccounts?a.allAccounts:n.split(","),s=0;s<m.length;s++)for(u=0;u<n.length;u++)m[s]==n[u]&&(t=1);p++}t?a.setAccount&&a.setAccount(r):a=new AppMeasurement(r);return a}AppMeasurement.getInstance=s_gi;window.s_objectID||(window.s_objectID=0);
function s_pgicq(){var r=window,a=r.s_giq,k,p,n;if(a)for(k=0;k<a.length;k++)p=a[k],n=s_gi(p.oun),n.setAccount(p.un),n.setTagContainer(p.tagContainerName);r.s_giq=0}s_pgicq();